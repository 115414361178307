export const scannersPageTestimonials = [
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
];

export const appPageTestimonials = [
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'APP-STORE',
    },
];

export const homePageTestimonials = [
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
];

export const aboutUsPageTestimonials = [
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'GOOGLE-PLAY',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'APP-STORE',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
    {
        icon: 'AMAZON',
    },
];
