import Vehiclebrands from "@/services/vehiclebrands.service";
import { storedLang } from "@/config/i18n.config";

const InitialWizardState = {
  expected: {},
  selector: {},
};

export default {
  namespaced: true,
  state: {
    selectedLetter: "A",
    alphabet: {},
    summary: null,
    brands: [],
    modelsSelectedByBrand: [],
    carsGenerationsSelectedByModel: [],
    dynamicVehiclesData: [],
    wizard: { ...InitialWizardState },
    brandsAndModels: [],
  },
  mutations: {
    alphabet(state, payload) {
      state.alphabet = payload;
    },
    summary(state, payload) {
      state.summary = payload;
    },
    selectedLetter(state, payload) {
      state.selectedLetter = payload;
    },
    brands(state, payload) {
      state.brands = payload;
    },
    modelsSelectedByBrand(state, payload) {
      state.modelsSelectedByBrand = payload;
    },
    wizard(state, payload) {
      state.wizard = payload;
    },
    carsGenerationsSelectedByModel(state, payload) {
      state.carsGenerationsSelectedByModel = payload;
    },
    dynamicVehiclesData(state, payload) {
      state.dynamicVehiclesData = payload;
    },
    resetWizard(state) {
      state.wizard = { ...InitialWizardState };
    },
    resetBrands(state) {
      state.brands = [];
    },
    resetModelsSelectedByBrand(state) {
      state.modelsSelectedByBrand = [];
    },
    resetCarsGenerationsSelectedByModel(state) {
      state.getCarGenerationByModelAndBrandId = [];
    },
    setBrandsAndModels(state, payload) {
      state.brandsAndModels = payload;
    },
  },
  actions: {
    getSummary({ commit, rootState: { language } }) {
      const languageCode = language?.lang?.code || storedLang;

      return Vehiclebrands.getSummary(
        languageCode
      ).then((data) => {
        const vehicleArr = [];
        const alphabet = {};

        let brandRef = null;
        let brandName = null;
        let modelRef = null;
        let modelName = null;
        let startYear = null;
        let endYear = null;

        const alpha = new Array(26)
          .fill(1)
          .map((_, i) => String.fromCharCode(65 + i));

        const brandInfoArray = []; // Store concise brand info keyed by brandId for usage later in short summary array

        for (let i = 0; i < data.length; i++) {
          const brandModels = data[i].models;
          for (let j = 0; j < brandModels.length; j++) {
            const modelGenerations = brandModels[j].generations;
            const brandData = modelGenerations[0].compatibility.selector;

            if (j == 0) {
                const { make, model } = brandData;
                brandInfoArray.push({
                    ...make,
                    models: [{
                      ...model,
                      searchKeyword: `${make.title} ${model.title}`
                    }],
                });
            } else {
              const { make, model } = brandData;
              brandInfoArray[i].models.push({
                ...model,
                searchKeyword: `${make.title} ${model.title}`
              })
            }

            for (let k = 0; k < modelGenerations.length; k++) {
              const currentGeneration = modelGenerations[k].compatibility;
              const { generation, make, model } = currentGeneration.selector;
              const generationRef = i + "-" + j + "-" + generation.id;
              let searchKeyword = "";

              if (j || k) {
                brandRef = null;
                brandName = null;
              } else {
                brandRef = make.id;
                brandName = make.title;
                for (let q = 0; q < alpha.length; q++) {
                  if (!alphabet[alpha[q]]) {
                    if (
                      alpha[q].toLocaleLowerCase() ===
                      make.id[0].toLocaleLowerCase()
                    ) {
                      alphabet[alpha[q]] = make.id;
                      break;
                    } else {
                      // alphabet[alpha[q]] = null;
                    }
                  }
                }
              }

              if (k) {
                modelRef = null;
                modelName = null;
              } else {
                modelRef = model;
                modelName = model.title;
              }

              searchKeyword =
                make.title + " " + model.title + " " + generation.title;

              startYear = modelGenerations[k].startYear;
              endYear = modelGenerations[k].endYear;

              vehicleArr.push({
                startYear,
                endYear,
                letter: make.title[0],
                brandRef,
                modelRef,
                generationRef,
                searchKeyword,
                brandName,
                brandId: make.id,
                modelName,
                modelId: model.id,
                generationName: generation.title,
                generationId: generation.id,
                features: currentGeneration.features,
              });
            }
          }
        }
        commit("setBrandsAndModels", brandInfoArray);
        commit("alphabet", alphabet);
        commit("summary", vehicleArr);
      });
    },
    getBrands({ commit, rootState: { language } }) {
      return Vehiclebrands.getBrands(
        language?.lang?.code
      ).then((data) => commit("brands", data));
    },
    getModelsByBrandId({ commit, rootState: { language } }, body) {
      return Vehiclebrands.getModelsByBrandId(
        body,
        language?.lang?.code
      ).then((data) => commit("modelsSelectedByBrand", data));
    },
    getCarGenerationByModelAndBrandId(
      { commit, rootState: { language } },
      body
    ) {
      return Vehiclebrands.getCarGenerationByModelAndBrandId(
        body,
        language?.lang?.code
      ).then((data) => commit("carsGenerationsSelectedByModel", data));
    },
    getDynamicVehiclesData({ commit, rootState: { language } }, body) {
      return Vehiclebrands.getDynamicVehiclesData(
        body,
        language?.lang?.code
      ).then((data) => {
        commit("dynamicVehiclesData", data.expected ?? data);
        commit("wizard", {
          selector: data.selector || {},
          expected: data.expected || {},
        });
      });
    },
  },
};
