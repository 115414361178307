<template>
    <div class="blog-page-inner">
        <div class="container">
            <div class="page">
                <h1>{{ openedArticle?.title }}</h1>
                <div class="date-and-author">
                    <img :src="tools?.images[0].url" loading="lazy" />
                    <div class="date-and-author-text">
                        <span class="author"> Carista team </span>
                        {{ isMobile ? '' : '/' }}
                        <span>{{
                            blogCreatedAtFormatted(openedArticle?.createdAt)
                        }}</span>
                    </div>
                </div>
                <div class="img-container">
                    <img
                        :src="openedArticle?.picture?.url"
                        :alt="openedArticle?.picture?.altText"
                        :title="openedArticle?.picture?.title"
                        loading="lazy"
                    />
                    <span class="tag">{{ openedArticle?.tags[0]?.name }}</span>
                </div>
            </div>
            <div class="article-and-table-contents">
                <div class="table-of-content-container">
                    <div class="toc-sticky-wrapper">
                        <div class="social-container">
                            <ArticalSocialMeta :article="openedArticle" />
                        </div>
                        <TableOfContents
                            :articleHeadings="articleHeadings"
                            :activeSectionId="activeSectionId"
                            @toc-item-click="handleTocClick"
                        />
                    </div>
                </div>
                <div class="article-container">
                    <!-- <div class="date-container">
                        <ShareButton :article="openedArticle" />
                        </div> -->
                    <div class="article-text">
                        <section
                            v-for="(section, index) in articleSections"
                            :ref="
                                (el) => {
                                    sectionRefs[index] = el;
                                }
                            "
                            :id="section.id"
                            :key="section.id + index"
                        >
                            <h2 v-if="section.h2">
                                {{ section.h2 }}
                            </h2>
                            <div v-html="section.content"></div>
                        </section>
                    </div>
                </div>
                <div></div>
            </div>
            <div class="related-articles">
                <div class="title-container">
                    <span>{{ t('page-blog_related-articles') }}</span>
                    <router-link
                        :to="`/blog`"
                        class="btn-feedback-outlined lighter"
                        v-if="!isMobile"
                    >
                        {{ t('page-blog_view-all') }} <ArrowRightIcon />
                    </router-link>
                </div>
                <div class="articles-container">
                    <div
                        class="article-card"
                        v-for="article in relatedArticles"
                    >
                        <div
                            class="img-container"
                            @click="
                                openArticle({
                                    name: 'slug',
                                    title: article.title,
                                    slug: article.slug,
                                    id: article.id,
                                })
                            "
                        >
                            <img
                                :src="article?.picture?.url"
                                :alt="article?.picture?.altText"
                                :title="article?.picture?.title"
                                loading="lazy"
                            />
                            <span class="tag">{{
                                article?.tags[0]?.name
                            }}</span>
                        </div>
                        <div class="article-card__text">
                            <div class="date-container">
                                <div class="date">
                                    {{
                                        blogCreatedAtFormatted(
                                            article?.createdAt
                                        )
                                    }}
                                    / Carista team
                                </div>
                                <ShareButton :article="article" />
                            </div>
                            <div
                                class="article-title"
                                @click="
                                    openArticle({
                                        name: 'slug',
                                        title: article.title,
                                        slug: article.slug,
                                        id: article.id,
                                    })
                                "
                            >
                                {{ article?.title }}
                            </div>
                            <button
                                @click="
                                    openArticle({
                                        name: 'slug',
                                        title: article.title,
                                        slug: article.slug,
                                        id: article.id,
                                    })
                                "
                                class="blog-btn-primary"
                            >
                                {{ t('page-blog_read-more') }}
                                <ArrowRightIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <router-link
                    :to="`/blog`"
                    class="btn-feedback-outlined lighter view-all-btn"
                    v-if="isMobile"
                >
                    {{ t('page-blog_view-all') }} <ArrowRightIcon />
                </router-link>
            </div>
        </div>
        <PromoSection
        :shouldOpenVendorModal="true"
            class="inner-blog-page-embark"
            fbEventParam="blog inner page"
        />
    </div>
</template>

<script setup>
import { computed, watch, onMounted, onUnmounted, ref, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import ArrowRightIcon from '../assets/icons/supportedCars/ArrowRightIcon.vue';
import PromoSection from '@/components/PromoSection.vue';
import ShareButton from '../components/blog/ShareButton.vue';
import ArticalSocialMeta from '@/components/blog/ArticalSocialMeta.vue';
import { blogCreatedAtFormatted } from '../utils/helpers';
import { useTranslation } from 'i18next-vue';
import TableOfContents from '@/components/blog/TableOfContents.vue';

const { t } = useTranslation(['page-blog', 'shared', 'translation']);

const router = useRouter();
const route = useRoute();
const store = useStore();

const isMobile = computed(() => store.state.device.isMobile);

const tools = computed(() => store.state.pages.tools);
const openedArticle = computed(() => store.state.blog?.openedArticle);

const sectionRefs = ref([]);

const articleSections = computed(() => {
    const doc = new DOMParser().parseFromString(
        openedArticle.value?.description,
        'text/html'
    );

    const sections = [];

    // Articles do not follow the same structure and this fixes the two most most common types
    const mainNode = doc.body.firstChild?.classList?.contains('blog')
        ? doc.body.firstChild.childNodes
        : doc.body.childNodes;

    let currentSection = { h2: null, id: null, content: '' };

    Array.from(mainNode).forEach((nodeEl) => {
        if (
            nodeEl.nodeType === Node.ELEMENT_NODE &&
            nodeEl.tagName.toLowerCase() === 'h2'
        ) {
            if (currentSection.content || currentSection.h2) {
                sections.push(currentSection);
            }
            // Start a new section with the heading text
            const h2 = nodeEl.textContent.trim();

            currentSection = {
                h2: h2,
                id: h2.replace(/\W+/g, '-').toLowerCase(),
                content: '',
            };

            return;
        }
        if (nodeEl.outerHTML) {
            currentSection.content += nodeEl.outerHTML;
        } else if (nodeEl.nodeType === Node.TEXT_NODE) {
            currentSection.content += nodeEl.textContent;
        }
    });

    if (currentSection.content || currentSection.h2) {
        sections.push(currentSection);
    }

    return sections;
});

const articleHeadings = computed(() => {
    return articleSections.value.filter((section) => section.h2);
});

const activeSectionId = ref('');
const articlesByTag = computed(
    () => store.state.blog?.articlesByTag?.articlesByTag?.articles
);

const relatedArticles = computed(() => {
    if (openedArticle.value?.relatedArticles.length > 0) {
        return openedArticle.value?.relatedArticles;
    }

    return articlesByTag.value;
});

async function getArticleData(routeSlug) {
    if (routeSlug) {
        await store.dispatch('blog/getArticleBySlug', routeSlug);

        if (!openedArticle.value) {
            router.push({ name: 'blog' });

            openNotificationModal({
                showSuccess: false,
                titleTxt: t('shared_page-not-found'),
                descriptionTxt: t('shared_article-doesnt-exist'),
                btnTxt: t('translation_ok'),
            });
        }

        if (openedArticle.value?.relatedArticles.length === 0) {
            const tagData = {
                relatedArticles: true,
                tagId: openedArticle.value.tags[0].id,
                page: 1,
                itemsPerPage: 4,
            };
            await store.dispatch('blog/getArticlesByTag', tagData);
        }
    } else {
        router.push('/blog');
    }
}

function openArticle(event) {
    if (route.params.slug !== event.slug) {
        router.push({
            name: event.name,
            params: {
                ...route.params,
                [event.name]: event.title,
                slug: event.slug,
                id: event.id,
            },
        });
    }
}

function openNotificationModal(option) {
    store.dispatch('modals/openModal', {
        currentModal: 'isNotificationModalOpen',
        data: option,
    });
}

function handleTocClick(elementId) {
    const delay = isMobile.value ? 250 : 0;
    setTimeout(() => {
        // There are transitions that need to finish before the scrollIntoView is triggered
        const element = document.getElementById(elementId);
        element.scrollIntoView({
            behavior: 'smooth',
        });
    }, delay);
}

let observer = null;

function setupObserver() {
    if (observer) {
        observer.disconnect();
    }

    const options = {
        root: null,
        rootMargin: '-50% 0px',
        threshold: 0,
    };

    observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && entry.target.id) {
                activeSectionId.value = entry.target.id;
            }
        });
    }, options);

    sectionRefs.value.forEach((section) => {
        if (section instanceof Element) {
            observer.observe(section);
        }
    });
}

onMounted(() => {
    document.body.classList.remove('overflow-hidden');
    getArticleData(route.params.slug);

    // remove the scrollbar of the video iframes
    setTimeout(() => {
        const iframe = document.querySelectorAll('.article-text iframe');
        iframe.forEach((item) => (item.scrolling = 'no'));
    }, 1000);
});

onUnmounted(() => {
    if (observer) {
        observer.disconnect();
        observer = null;
    }
});

watch(route, (to, from) => {
    getArticleData(to.params.slug);
});

watch(articleSections, async (newSections) => {
    if (!newSections.length) {
        return;
    }

    await nextTick();
    setupObserver();
});
</script>
