import axios from "./http.service";

class Vehiclebrands {
  getBrands(langCode) {
    return axios
      .get(`${process.env.VUE_APP_API_VEHICLES}/wizard?lang=${langCode}`)
      .then((res) => res.data.expected.options);
  }

  getModelsByBrandId(brandId, langCode) {
    return axios
      .get(`/wizard/${brandId}/${langCode}`)
      .then((res) => res.data.expected.options);
  }

  getCarGenerationByModelAndBrandId({ brand_id, model_id }, langCode) {
    return axios
      .get(
        `${process.env.VUE_APP_API_VEHICLES}/wizard?lang=${brand_id}/${model_id}?lang=${langCode}`
      )
      .then((res) => res.data.expected.options);
  }

  getSummary(langCode) {
    return axios
      .get(`${process.env.VUE_APP_API_VEHICLES}/summary?lang=${langCode}`)
      .then(({ data }) => data);
  }

  getDynamicVehiclesData(
    { make = null, model = null, generation = null },
    langCode
  ) {
    let queries = "";

    if (make) {
      queries += `/${make}`;
    }
    if (model) {
      queries += `/${model}`;
    }
    if (generation) {
      queries += `/${generation}`;
    }

    return axios
      .get(
        `${process.env.VUE_APP_API_VEHICLES}/wizard${queries}?lang=${langCode}`
      )
      .then((res) => res.data);
  }
}

export async function fetchVehicleOptions({ make = null, model = null, generation = null }, langCode) {
  const pathSegments = [make, model, generation].filter(Boolean).join('/');
  const url = `${process.env.VUE_APP_API_VEHICLES}/wizard${pathSegments ? `/${pathSegments}` : ''}?lang=${langCode}`;

  const { data } = await axios.get(url);

  return data?.expected?.options || data;
}

export default new Vehiclebrands();
