<template>
    <div class="comparison-table-container">
        <div class="title">{{ t('page-pricing_comparison-table') }}</div>
        <ActionButton
            v-if="isMobile && !isTableOpened"
            @click="toggleTable"
            :customText="t('shared_choose-adapter')"
            :showArrow="true"
            :isArrowRotated
            class="show-table-button"
        />
        <table v-if="!isMobile || isTableOpened">
            <thead>
                <tr>
                    <th>
                        <div class="head-cell">
                            <img
                                :src="pricing?.children[0]?.images[0].url"
                                :alt="
                                    pricing?.children[0]?.images[0].imageMeta
                                        ?.metaAltDescription
                                "
                                :title="
                                    pricing?.children[0]?.images[0].imageMeta
                                        ?.metaTitle
                                "
                                loading="lazy"
                            />
                            <div class="hide-on-mobile head-cell-text-content">
                                <h5 class="sub-title">
                                    {{ pricing?.headings?.h2[2] }}
                                </h5>
                                <span class="price price-highlight">
                                    {{ adapterPrices.currency
                                    }}{{ adapterPrices.adapterPrice }}
                                </span>
                            </div>
                        </div>
                        <ActionButton
                            @click="handleClick(ADAPTER_TYPE.OBD)"
                            :isLink="isSingleVendor(ADAPTER_TYPE.OBD)"
                            :linkUrl="isSingleVendor ? getSiteUrl(ADAPTER_TYPE.OBD, adapterPrices.vendors) : ''"
                            class="hide-on-mobile"
                            linkTarget="_blank"
                        />
                    </th>
                    <th>
                        <div class="head-cell">
                            <img
                                :src="pricing?.children[0]?.images[1].url"
                                :alt="
                                    pricing?.children[0]?.images[1].imageMeta
                                        ?.metaAltDescription
                                "
                                :title="
                                    pricing?.children[0]?.images[1].imageMeta
                                        ?.metaTitle
                                "
                                loading="lazy"
                            />
                            <div class="hide-on-mobile head-cell-text-content">
                                <h5 class="sub-title">
                                    {{ pricing?.headings?.h2[3] }}
                                </h5>
                                <span class="price price-highlight">
                                    {{ adapterPrices.currency
                                    }}{{ adapterPrices.evoAdapterPrice }}
                                </span>
                            </div>
                        </div>
                            <ActionButton
                                @click="handleClick(ADAPTER_TYPE.EVO)"
                                :isLink="isSingleVendor(ADAPTER_TYPE.EVO)"
                                :linkUrl="isSingleVendor ? getSiteUrl(ADAPTER_TYPE.EVO, adapterPrices.vendors) : ''"
                                :isHighlighted="true"
                                class="hide-on-mobile"
                                linkTarget="_blank"
                            />
                    </th>
                </tr>
            </thead>
            <tr class="spacer hide-on-mobile"></tr>
            <tbody>
                <template
                    v-for="(item, index) in obdItems"
                    :key="'row' + '-' + index"
                >
                    <tr>
                        <td>
                            <DashIcon v-if="item === null" />
                            <p v-else>{{ item }}</p>
                        </td>
                        <td>
                            <p>
                                {{
                                    !isMobile || showTooltip(index)
                                        ? evoItems[index]
                                        : null
                                }}
                                <ComparisonTooltip
                                    v-if="!showTooltip(index)"
                                    :tooltipBase="evoItems[index]"
                                    :tooltipText="tooltipTexts[index]"
                                />
                            </p>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <ActionButton
            v-if="isMobile && isTableOpened"
            @click="toggleTable"
            :customText="t('shared_close')"
            :showArrow="true"
            :isArrowRotated
            class="hide-table-button"
        />
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTranslation } from 'i18next-vue';
import { facebookGoogleAnalitycs } from '@/utils/helpers';
import DashIcon from '@/assets/icons/DashIcon';
import ComparisonTooltip from './ComparisonTooltip.vue';
import appConfig from '@/config/app.config';
import ActionButton from '../UI-elements/ActionButton.vue';
import { ADAPTER_TYPE } from '@/utils/constants.js';

const isTableOpened = ref(false);
const isArrowRotated = ref(false);

const store = useStore();
const route = useRoute();

const { t } = useTranslation(['shared', 'page-pricing']);

const pricing = computed(() => store.state.pages?.pricing);
const adapterPrices = computed(() => store.state.prices?.prices);

const isInUK = computed(() => store.state.prices?.prices?.countryCode === 'GB');
const isMobile = computed(() => store.state.device.isMobile);

const obdItems = computed(() => {
    let arrObd = [
        null,
        null,
        t('page-pricing_hs-can-protocol'),
        null,
        null,
        t('page-pricing_bluetooth-5-0'),
        t('page-pricing_8-bit-processor'),
        null,
        t('page-pricing_elm327-1-4-standard'),
        null,
        t('page-pricing_ios-android'),
    ];

    if (isInUK.value && appConfig.saleEnabled) {
        arrObd.unshift(null);
    }

    return arrObd;
});

const evoItems = computed(() => {
    let arrEvo = [
        t('page-pricing_unlocks-sfd-protection'),
        t('page-pricing_ultra-fast-connection'),
        t('page-pricing_hs-can-protocol'),
        t('page-pricing_ms-can-protocol'),
        t('page-pricing_sw-can protocol'),
        t('page-pricing_bluetooth-5-1'),
        t('page-pricing_32-bit-arm-processor'),
        t('page-pricing_battery-saver-sleep-mode'),
        t('page-pricing_elm327-2-2-standard'),
        t('page-pricing_elm329-standard'),
        t('page-pricing_ios-android'),
    ];

    if (isInUK.value && appConfig.saleEnabled) {
        arrEvo.unshift(t('shared_provides-FREE-lifetime-diagnostics'));
    }

    return arrEvo;
});

const tooltipTexts = computed(() => {
    let arrTooltips = [
        t('page-pricing_unlocks-sfd-protection-tooltip'),
        null,
        t('page-pricing_hs-can-protocol-tooltip'),
        t('page-pricing_ms-can-protocol-tooltip'),
        t('page-pricing_sw-can protocol-tooltip'),
        t('page-pricing_bluetooth-5-1-tooltip'),
        t('page-pricing_32-bit-arm-processor-tooltip'),
        t('page-pricing_battery-saver-sleep-mode-tooltip'),
        t('page-pricing_elm327-2-2-standard-tooltip'),
        t('page-pricing_elm329-standard-tooltip'),
        null,
    ];

    if (isInUK.value && appConfig.saleEnabled) {
        arrTooltips.unshift(null);
    }

    return arrTooltips;
});

const showTooltip = (index) => {
    if (isInUK.value && appConfig.saleEnabled) {
        return index === 0 || index === 2 || index === 11;
    }
    return index === 1 || index === 10;
};

const toggleTable = () => {
    if (isMobile && isTableOpened.value) {
        const comparisonTable = document.querySelector('.comparison-table-container');
        comparisonTable.scrollIntoView();
    }

    isTableOpened.value = isArrowRotated.value = !isTableOpened.value;
};

const handleClick = (adapterType) => {
  const siteUrl = getSiteUrl(adapterType, adapterPrices.value.vendors);
  const isAdapterEvo = adapterType === ADAPTER_TYPE.EVO;
  const eventName = buildEventName(route.name);
  const position = 'comparison table';

  if (adapterPrices?.value.vendors.length <= 1) {
    emitAnalyticsEvent(eventName, siteUrl, adapterPrices.value.vendors[0]?.siteName, isAdapterEvo, position);

    return;
  }

  emitAnalyticsEvent(eventName, siteUrl, adapterPrices.value.vendors[0]?.siteName, isAdapterEvo, position);
  showModal(adapterType);
}

const buildEventName = (routeName) => {
  let eventName = "buy_now";
  if (routeName === 'pricing') {
    eventName += "_pricing";
  } else if (routeName === 'pricing-evo') {
    eventName += "_evo_pricing";
  }
  return eventName;
}

const getSiteUrl = (adapterType, vendors) => {
  return adapterType === 'evo'
    ? vendors[0]?.evoSiteUrl
    : vendors[0]?.siteUrl;
}

const showModal =  (adapterType) => {
  store.dispatch("modals/openModal", {
    currentModal: "isVendorModalOpen",
    data: adapterType || true,
  });
}

const isSingleVendor = function(adapterType){
    if (adapterPrices.value.vendors?.length == 1) {
        return getSiteUrl(adapterType, adapterPrices.value.vendors);
    }

    return false;
};

const emitAnalyticsEvent = (eventName, siteUrl, siteName, isEvo, position) => {
  facebookGoogleAnalitycs(eventName, {
      siteName,
      siteUrl,
      button_position: position,
      adapterName: isEvo ? 'EVO' : 'OBD',
  })
}
</script>

<style lang="scss" scoped>
.comparison-table-container {
    scroll-margin-top: 110px;
}

.hide-table-button {
    margin-top: 40px;
}

thead {
    border-radius: 14px;
    -webkit-box-shadow: 0px 3px 8px 5px rgba(8, 15, 52, 0.04);
    box-shadow: 0px 3px 8px 0px rgba(8, 15, 52, 0.04);

    @media (max-width: $screen-md) {
        box-shadow: none;
    }
}

th {
    flex-direction: column;

    button {
        margin: 0px auto 0px auto;
    }

    a {
        margin: 0px auto 0px auto;
    }
}

.spacer {
    height: 50px;
}

.title {
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: $screen-md) {
        text-align: left;
    }
}

.sub-title {
    font-size: 24px;
    font-weight: 500;
}

.price-highlight {
    font-weight: 500;
    font-size: 32px;
}

.head-cell {
    margin-bottom: 34px;
    border: none !important;

    @media (max-width: $screen-md) {
        margin-bottom: 0px;
    }
}

.head-cell-text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
</style>
