<template>
  <div class="blog-page" v-if="initialBlogData">
    <BreadCrumb />
    <BlogInnerPage v-if="route.params?.slug" />
    <div v-else class="page">
      <div class="container">
        <h1>{{ t("page-blog_blog") }}</h1>
        <div class="latest-article">
          <div
            class="img-container"
            @click="openArticle({
              name: 'slug',
              title: latestArticle.title,
              slug: latestArticle.slug,
              id: latestArticle.id
            })"
          >
            <img
              :src="latestArticle?.picture?.url"
              :alt="latestArticle?.picture?.altText"
              :title="latestArticle?.picture?.title"
              loading="lazy"
            />
            <span class="tag">{{ latestArticle?.tags[0]?.name }}</span>
          </div>
          <div class="latest-article__text">
            <div class="date-container">
              <div class="date">{{ blogCreatedAtFormatted(latestArticle?.createdAt) }} / Carista team</div>
              <ShareButton :article="latestArticle" />
            </div>
            <div
              class="article-title"
              @click="openArticle({
                name: 'slug',
                title: latestArticle.title,
                slug: latestArticle.slug,
                id: latestArticle.id
              })"
            >{{ latestArticle?.title }}</div>
            <div class="article-summary" v-if="latestArticle?.shortDescription">{{ latestArticle?.shortDescription }}</div>
            <div class="article-summary" v-else v-html="latestArticle?.description.substring(0, 250) + '...'"></div>
            <button
              @click="openArticle({
                name: 'slug',
                title: latestArticle.title,
                slug: latestArticle.slug,
                id: latestArticle.id
              })"
              class="blog-btn-primary"
            >
              {{ t("page-blog_read-more") }} <ArrowRightIcon />
            </button>
          </div>
        </div>
        <div class="all-articles-container" ref="targetRef">
          <h1>{{ t("page-blog_all-articles") }}</h1>
          <div class="categories-container">
            <ul class="container__list">
              <li class="container__list__item" :class="{ 'active': activeCategory === 0 }" @click="changeCategory(0)">
                {{ t("page-blog_all-articles") }}
              </li>
              <li
                class="container__list__item"
                :class="{ 'active': activeCategory === tag.id }"
                v-for="tag in allTags"
                @click="changeCategory(tag.id)"
              >
                {{ tag.name }}
              </li>
            </ul>
          </div>
          <div class="articles-container">
            <ArticleCard
              class="article-card"
              v-for="article in articlesGrid"
              :article="article"
              :loading="loading"
            />
          </div>
          <div class="pagination">
            <span @click="prevPage"><PaginationArrowLeft /></span>
            <span class="page-numbers">
              <span
                class="page-number"
                :class="{ active: currentPage === 1 }"
                @click="changePage(firstPage)"
              >{{ firstPage }}</span>
              <span
                class="page-number"
                :class="{ active: (currentPage !== 1 && currentPage !== lastPage) || (currentPage === 2 && currentPage === lastPage) }"
                @click="changePage(secondPage)"
                v-if="lastPage > 1"
              >{{ secondPage }}</span>
              <span
                class="page-number"
                :class="{ active: currentPage === lastPage }"
                @click="changePage(thirdPage)"
                v-if="lastPage > 2"
              >{{ thirdPage }}</span>
            </span>
            <span @click="nextPage"><PaginationArrowRight /></span>
          </div>
        </div>
      </div>
      <PromoSection
      :shouldOpenVendorModal="true"
        fbEventParam="blog page"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import ArrowRightIcon from "@/assets/icons/supportedCars/ArrowRightIcon.vue";
import PaginationArrowLeft from "@/assets/icons/PaginationArrowLeft.vue";
import PaginationArrowRight from "@/assets/icons/PaginationArrowRight.vue";
import BreadCrumb from "../components/breadcrumb/BreadCrumb.vue";
import BlogInnerPage from "./BlogInnerPage.vue";
import PromoSection from '@/components/PromoSection.vue';
import ShareButton from "../components/blog/ShareButton.vue";
import ArticleCard from "../components/blog/ArticleCard.vue";
import { blogCreatedAtFormatted, createMetaInfo } from "../utils/helpers";
import { useTranslation } from "i18next-vue";
import { useHead } from '@unhead/vue';

const { t } = useTranslation(['page-blog', 'shared']);

const store = useStore();
const router = useRouter();
const route = useRoute();

const loading = ref(false);
const lastPage = ref(1);
const currentPage = ref(1);
const activeCategory = ref(0);
const firstPage = ref(1);
const secondPage = ref(2);
const thirdPage = ref(3);
const itemsPerPage = ref(9);
const targetRef = ref(null);

const isMobile = computed(() => store.state.device.isMobile);

const langCode = computed(() => store.state.language.lang?.code);
const initialBlogData = computed(() => store.state.blog?.initialBlogData);
const allArticles = computed(() => store.state.blog?.allArticles?.articles);
const lastPageAllArticles = computed(() => store.state.blog?.allArticles?.lastPage);
const latestArticle = computed(() => store.state.blog?.initialBlogData?.latestArticle?.article);
const allTags = computed(() => store.state.blog?.allTags?.tags);
const articlesByTag = computed(() => store.state.blog?.articlesByTag?.articlesByTag?.articles);
const lastPageArticlesByTag = computed(() => store.state.blog?.articlesByTag?.articlesByTag?.lastPage);
const openedArticle = computed(() => store.state.blog?.openedArticle);
const metaFields = computed(() => store.state.blog?.initialBlogData?.blogMetaFields);

const articlesGrid = computed(() => {
  if (activeCategory.value === 0) {
    return allArticles.value;
  }

  return articlesByTag.value;
});

onMounted(async () => {
  document.body.classList.remove("overflow-hidden");
  await isMobile.value;
  if (isMobile.value) itemsPerPage.value = 6;

  const pageData = {
    page: 1,
    itemsPerPage: isMobile.value ? 6 : 9,
  };
  store.dispatch("blog/getInitialBlogData", pageData).then(() => {
    lastPage.value = lastPageAllArticles.value;
  });
});

function getAllArticles(pageData) {
  loading.value = true;
  store.dispatch("blog/getAllArticles", pageData).then(() => {
    lastPage.value = lastPageAllArticles.value;
    loading.value = false;
  });

  activeCategory.value = 0;
}

function getArticlesByTag(pageData) {
  loading.value = true;
  store.dispatch("blog/getArticlesByTag", pageData).then(() => {
    lastPage.value = lastPageArticlesByTag.value;
    loading.value = false;
  });
}

function changeCategory(category) {
  currentPage.value = 1;
  firstPage.value = 1;
  secondPage.value = 2;
  thirdPage.value = 3;

  if (activeCategory.value !== category) activeCategory.value = category;

  const pageData = {
    tagId: category,
    page: 1,
    itemsPerPage: itemsPerPage.value,
  };

  if (category === 0) {
    getAllArticles();
  } else {
    getArticlesByTag(pageData);
  }
}

function openArticle(event) {
  router.push({
    name: event.name,
    params: {
      ...route.params,
      [event.name]: event.title,
      slug: event.slug,
      id: event.id,
    },
  });
}

function prevPage() {
  if (currentPage.value > 1) {
    const page = currentPage.value - 1;
    changePage(page);
  }
}

function nextPage() {
  if (currentPage.value < lastPage.value) {
    const page = currentPage.value + 1;
    changePage(page);
  }
}

function changePage(page) {
  if (page < 1) page = 1;
  if (page > lastPage.value) page = lastPage.value;

  if (page === 1 || page === 2) {
    firstPage.value = 1;
    secondPage.value = 2;
    thirdPage.value = 3;
  } else if (page === lastPage.value) {
    firstPage.value = page - 2;
    secondPage.value = page - 1;
    thirdPage.value = page;
  } else {
    firstPage.value = page - 1;
    secondPage.value = page;
    thirdPage.value = page + 1;
  }

  currentPage.value = page;

  let pageData = {
    page: page,
    itemsPerPage: itemsPerPage.value,
  };

  if (activeCategory.value === 0) {
    getAllArticles(pageData);
  } else {
    pageData = {
      ...pageData,
      tagId: activeCategory.value
    }
    getArticlesByTag(pageData);
  }

  targetRef.value.scrollIntoView({ behavior: 'instant' });
}

watch(route, (to, from) => {
  if (to.params.lang !== from.params.lang ) {
    store.dispatch("blog/getAllTags");
  }
});

useHead(() => {
  let canonicalResult = `${process.env.VUE_APP_APP}/en/blog`;
  let metaTitle = metaFields.value?.blogMetaTitle;
  let metaDes = metaFields.value?.blogMetaDescription;
  let articleImgUrl = "";
  let articleImgAlt = "";

  if (route.params.slug) {
    canonicalResult += `/${route.params.slug}`;

    metaTitle = openedArticle.value?.metaTitle;
    metaDes = openedArticle.value?.metaDescription;
    articleImgUrl = openedArticle.value?.picture?.url;
    articleImgAlt = openedArticle.value?.picture?.altText;
  }

  const metaData = {
    metaDescription: metaDes,
    canonicalHref: canonicalResult,
    hreflangTag: 'en',
    articleImgUrl,
    articleImgAlt
  }

  return createMetaInfo({
    title: metaTitle,
    pageMeta: metaData,
  });
});
</script>
