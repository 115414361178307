<template>
    <div class="content-media-container">
        <div class="inner-container">
            <SkeletonLoader
                v-if="!content.h2"
                :height="100"
                class="loader-title"
            />
            <h2 v-else class="title">{{ content.h2 }}</h2>
            <div class="wrapper">
                <SkeletonLoader v-if="!content.p1" :width="isMobile ? 330 : 500" :lines="8" />
                <SkeletonLoader v-if="!content.p1" :width="isMobile ? 330 : 500" :height="200" :lines="1" />
                <template v-else>
                    <p v-html="content.p1"></p>
                    <p v-html="content.p2"></p>
                    <p v-html="content.p3"></p>
                    <FaqCompact :items="faq" class="faq-wrapper" />
                </template>
            </div>
            <div class="image-wrapper">
                <div>
                    <SkeletonLoader
                        v-if="!content?.image?.url || !imageLoaded"
                        :width="isMobile ? 330 : 400"
                        :height="500"
                    />
                    <img
                        :src="content?.image?.url"
                        :alt="content?.image?.imageMeta?.metaAltDescription"
                        :title="content?.image?.imageMeta?.metaTitle"
                        @load="imageLoaded = true"
                        style="display: none"
                    />
                </div>
                <img
                    v-if="imageLoaded"
                    :src="content?.image?.url"
                    :alt="content?.image?.imageMeta?.metaAltDescription"
                    :title="content?.image?.imageMeta?.metaTitle"
                    loading="lazy"
                />
                <div class="image-text">
                    <SkeletonLoader v-if="!content?.image?.url" :lines="2" />
                    <div class="left-text">
                        <h6>{{ content.h6 }}</h6>
                        <span>{{ content.preText }}</span>
                    </div>
                    <LinkedInIcon @click="handleClick" :color="'#1B1E21'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onBeforeMount, watch } from 'vue';
import { useStore } from 'vuex';
import { FAQ_CATEGORY_ID } from '@/utils/constants';
import FaqCompact from './FaqCompact.vue';
import SkeletonLoader from './UI-elements/SkeletonLoader.vue';
import LinkedInIcon from '@/assets/icons/LinkedInIcon.vue';

const props = defineProps({
    content: Object,
});

const imageLoaded = ref(false);

const store = useStore();

const isMobile = computed(() => store.state.device.isMobile);
const langCode = computed(() => store.state.language.lang?.code);

const faq = computed(() => {
    return store.state.faq?.faq[FAQ_CATEGORY_ID.ABOUT_US - 1];
});

const handleClick = () => {
    if (!props.content?.url) {
        return;
    }

    window.open(props.content.url, '_blank');
};


watch(langCode, (newVal) => {
  store.dispatch("faq/getFaq", { lang: newVal, categoryId: FAQ_CATEGORY_ID.ABOUT_US });
});

onBeforeMount(() => {
    store.dispatch('faq/getFaq', {
        lang: langCode.value,
        categoryId: FAQ_CATEGORY_ID.ABOUT_US,
    });
});
</script>

<style lang="scss" scoped>
.content-media-container {
    background: linear-gradient(165deg, #e6e7ea 0%, #f8f9fa 91.8%);
    display: flex;
    justify-content: center;
}

.inner-container {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: max(90px, 20%);
    padding: 150px 128px;
    max-width: $container-max-width;

    @media (max-width: $screen-md) {
        grid-template-columns: auto;
        padding: 96px 24px;
    }
}

.title {
    max-width: 680px;
    grid-column-start: 1;
    color: $color-neutral-deep;
    justify-self: end;

    @media (max-width: $screen-md) {
        font-size: 36px;
        justify-self: center;
    }
}

.loader-title {
    margin-bottom: 120px;
}

.wrapper {
    grid-column-start: 1;
    max-width: 680px;
    justify-self: end;
    width: 100%;
    p {
        color: $color-neutral-deep;
        margin-top: 24px;
    }

    .faq-wrapper {
        margin-top: 78px;
    }

    @media (max-width: $screen-md) {
        justify-self: center;
    }
}

.image-wrapper {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    max-width: 620px;
    max-height: 840px;

    justify-self: center;

    img {
        border-radius: 24px;
    }

    h6 {
        color: $color-neutral-deep;
    }

    span {
        color: $color-neutral-dark;

        font-size: 16px;
        font-weight: 500;
        line-height: 22.5px;
    }

    @media (max-width: $screen-md) {
        grid-column-start: 1;
        grid-row-start: 2;
        grid-row-end: auto;
        margin: 42px 0px 18px;
    }
}

.image-text {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;

    svg {
        width: 18px;
        height: 20px;

        &:hover {
            cursor: pointer;
        }
    }
}
</style>
