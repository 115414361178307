<template>
    <div class="search-results">
        <div v-if="!hasQuery" class="results-placeholder">
            <SearchIconResponsive
                :width="92"
                :height="92"
                :hasGradient="true"
            />
            <h4>{{ t('page-supported-cars_search-by-brand-or-model') }}</h4>
        </div>
        <div v-if="hasQuery" class="results-list">
            <span v-if="!renderBlocks.length" class="no-results">
                {{ t('page-supported-cars_no-results') }}
            </span>
            <ul
                v-for="(block, i) in renderBlocks"
                :key="block.brandId + i"
                class="block"
            >
                <li @click="selectBrand(block.brandId)" class="brand">
                    {{ block.brandTitle }}
                </li>
                <li
                    v-for="(model, j) in block.models"
                    @click="selectModel(block.brandId, model.modelId)"
                    :key="model.modelId + j"
                    class="model"
                >
                    {{ model.modelTitle }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import SearchIconResponsive from '@/assets/icons/SearchIconResponsive.vue';
import { useTranslation } from 'i18next-vue';

const props = defineProps({ results: Array, brands: Array, hasQuery: Boolean });
const emit = defineEmits(['selectBrand', 'selectModel']);

const { t } = useTranslation(['page-supported-cars']);

const selectBrand = (brand) => emit('selectBrand', brand);
const selectModel = (brand, model) => emit('selectModel', brand, model);

const findBrandTitle = (brandId) => {
    const brand = props.brands?.find((item) => item.id === brandId);
    return brand ? brand.title : brandId;
};

const renderBlocks = computed(() => {
    if (!props.results?.length) {
        return [];
    }

    const brandMap = new Map();

    for (const currentItem of props.results) {
        const item = currentItem.item;
        const { brandId, type, modelId, modelTitle } = item;

        if (!brandMap.has(brandId)) {
            brandMap.set(brandId, {
                brandId,
                brandTitle: findBrandTitle(brandId),
                models: [],
            });
        }

        if (type === 'model') {
            brandMap.get(brandId).models.push({
                modelId,
                modelTitle,
            });
        }
    }

    return Array.from(brandMap.values());
});
</script>

<style lang="scss" scoped>
.search-results {
    padding: 0px 32px;
    overflow-y: auto;
    background-color: $color-neutral-lightest;
    margin: 24px 0px;

    @media (max-width: $screen-md) {
        padding: 0px 18px;
        margin: 10px 0px 20px;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
    }

    &::-webkit-scrollbar-track {
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-neutral-light;
        border-radius: 4px;
    }
}

.results-placeholder {
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $screen-md) {
        height: 290px;
    }

    path {
        fill: red !important;
        background-color: green;
    }
    h4 {
        position: relative;
        top: -10px;
        font-size: 20px;
        font-weight: 500;

        @media (max-width: $screen-md) {
            text-align: center;
        }
    }
}

.search-results {
    height: 475px;

    @media (max-width: $screen-md) {
        height: 290px;
    }
}

ul {
    &.block:not(:last-child) {
        border-bottom: 1px solid $color-neutral-light;
    }
}

li {
    color: $color-neutral-darkest;
    font-weight: 500;

    &:hover {
        cursor: pointer;
    }

    &.brand {
        margin: 24px 5px 18px;
        font-size: 24px;

        @media (max-width: $screen-md) {
            font-size: 20px;
        }
    }

    &.model {
        margin: 18px 5px;
        font-size: 20px;

        @media (max-width: $screen-md) {
            font-size: 18px;
        }

        &:last-child {
            margin-bottom: 24px;
        }
    }
}

.no-results {
    color: $color-neutral-darkest;
    font-weight: 500;
    margin: 18px 5px;
    font-size: 20px;
}
</style>
