<template>
  <Transition name="fade-slide">
    <div
      v-if="vendorModalData && prices"
      class="modal-wrapper"
      @click.self="$store.dispatch('resetModalStates')"
    >
      <div class="vendor-modal">
        <p class="vendor-modal__title">
          {{ t("shared_choose-a-vendor") }}
          <button
            name="close-btn"
            class="vendor-modal__title__close-btn"
            @click="closeVendorModal"
          >
            <XCloseIcon />
          </button>
        </p>
        <ul class="vendor-modal__vendors-list" ref="vendors">
          <li
            v-for="vendor in prices.vendors"
            :key="vendor.siteUrl"
          >
            <a :href="getVendorUrl(vendor)" class="vendor_link" target="_blank" rel="nofollow">
              <img
                :src="
                  vendor.image
                    ? `${vendor.image}`
                    : './images/logo.png'
                "
                :alt="vendor?.altUrl"
                :class="{ caristaLogo: !vendor.image }"
                loading="lazy"
              />
              {{ vendor.siteName }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTranslation } from "i18next-vue";
import XCloseIcon from "@/assets/icons/XCloseIcon.vue";
import { facebookGoogleAnalitycs } from "@/utils/helpers";

const { t } = useTranslation(['shared']);
const store = useStore();
const route = useRoute();

const vendors = ref(null);

const vendorModalData = computed(() => store.state.modals?.isVendorModalOpen);
const prices = computed(() => store.state.prices?.prices);
const adapterName = computed(() => vendorModalData.value.toUpperCase());

function closeVendorModal() {
  store.dispatch("resetModalStates");
}

function getVendorUrl(vendor) {
  return vendorModalData.value === 'evo' ? vendor?.evoSiteUrl : vendor?.siteUrl;
}

watch(vendorModalData, (isOpen) => {
  // TODO: Research when this is used
  if (isOpen && prices.value.vendors.length <= 1) {
    setTimeout(() => {
      facebookGoogleAnalitycs(eventName.value, {
        siteName: prices.value.vendors[0]?.siteName,
        siteUrl: prices.value.vendors[0]?.siteUrl,
        adapterName: adapterName.value,
      });
      vendors.value.children[0].firstChild.click();
      closeVendorModal();
    }, 0);
  }
});
</script>
