<template>
    <div class="gallery-wrapper">
        <div class="inner-container">
            <h2>{{ t('page-about-us_we-build-you-drive') }}</h2>
            <ul class="gallery">
                <li v-for="image in images">
                    <img
                        :src="image.url"
                        :alt="image.imageMeta?.metaAltDescription"
                        :title="image.imageMeta?.metaTitle"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { useTranslation } from 'i18next-vue';

const { t } = useTranslation(['page-about-us']);

const props = defineProps({
    images: {
        type: [Array],
        default: [],
    },
});
</script>

<style lang="scss" scoped>
h2 {
    text-align: center;
    margin-bottom: 78px;

    @media (max-width: $screen-md) {
        margin-bottom: 42px;
    }
}
.gallery-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 150px 128px;
    background-color: $color-white;

    @media (max-width: $screen-md) {
        padding: 96px 24px;
    }

    .inner-container {
        max-width: $container-max-width;
    }
}

ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    gap: 70px;

    @media (max-width: $screen-xl) {
        gap: 42px;
    }

    @media (max-width: $screen-lg) {
        gap: 32px;
    }

    @media (max-width: $screen-md) {
        flex-flow: row wrap;
        gap: 12px;
    }
}

li {
    max-width: 800px;
    height: 33vh;
    flex-grow: 1;

    &:nth-child(even) {
        transform: scale(0.9);
    }

    @media (max-width: $screen-md) {
        flex: auto;
        height: 128px;

        &:nth-child(2) {
            order: -1;
        }
    }
}

li:last-child {
    // There's no science in using "10" here. It just looks good.
    flex-grow: 10;
}

img {
    max-height: 100%;
    min-width: 100%;
    max-width: 800px;

    border-radius: 42px;
    object-fit: cover;

    @media (max-width: $screen-md) {
        border-radius: 14px;
    }
}
</style>
