<template>
    <div class="card-wrapper">
        <div class="card-icon">
            <component :is="icon" class="icon-svg" />
        </div>
        <div class="card-content">
            <h5 class="card-title">{{ title }}</h5>
            <p class="card-description">{{ description }}</p>
            <!-- Conditionally render router-link or anchor link -->
            <component
                :is="isExternalLink ? 'a' : 'router-link'"
                class="card-action"
                :href="isExternalLink ? actionLink : null"
                :to="!isExternalLink ? `/${langCode}${actionLink}` : null"
            >
                {{ actionLabel }}
                <ChevronIcon color="#fff" class="arrow-icon" />
            </component>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import ChevronIcon from '@/assets/icons/ChevronIcon.vue';
import { useStore } from 'vuex';

const props = defineProps({
    icon: Object,
    title: String,
    description: String,
    actionLabel: String,
    actionLink: String,
    useRouterLink: {
        type: Boolean,
        default: false,
    }
});

const store = useStore();

const langCode = computed(() => store.state.language.lang?.code);

const isExternalLink = computed(() => !props.useRouterLink);
</script>

<style lang="scss" scoped>
.card-wrapper {
    display: flex;
    gap: 42px;
    justify-content: flex-start;
    padding: 1.5rem;
    border-radius: 12px;
    width: 100%;
    max-width: 468px;
    transition: transform 0.2s ease-in-out;

    @media (max-width: $screen-md) {
        gap: 24px;
        padding: 0;
    }
}

.card-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.card-icon {
    font-size: 2rem;
}

.card-title {
    color: $color-white;
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 0.5rem;
}

.card-description {
    color: $color-gray;
    font-size: 16px;
    font-weight: 500;
}

.card-action {
    display: inline-block;
    margin-top: 1rem;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color: $color-white;

    .arrow-icon {
        margin-left: 8px;
        height: 9px;
        width: 18px;
        transform: rotate(-90deg);
    }
}
</style>
