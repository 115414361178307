<template>
    <div class="store-buttons">
        <a
            v-for="store in stores"
            :key="store.platform"
            :href="store.url"
            target="_blank"
            rel="nofollow"
            class="btn-store-outlined"
            @click="trackClick(store.platform)"
        >
            <component :is="store.icon" :color="'#1B1E21'" />
        </a>
    </div>
</template>

<script setup>
import AppStoreIcon from '@/assets/icons/AppStoreIcon.vue';
import GoogePlayIcon from '@/assets/icons/GoogePlayIcon.vue';
import appConfig from '@/config/app.config';
import { facebookGoogleAnalitycs } from '@/utils/helpers';

const props = defineProps({
    eventName: {
        type: String,
        required: true,
    },
});

const stores = [
    {
        platform: 'App Store',
        url: appConfig.appleAppUrl || '#',
        icon: AppStoreIcon,
    },
    {
        platform: 'Google Play',
        url: appConfig.googleAppUrl || '#',
        icon: GoogePlayIcon,
    },
];

const trackClick = (platform) => {
    facebookGoogleAnalitycs(props.eventName, { platform });
};
</script>

<style lang="scss" scoped>
.store-buttons {
    display: flex;
    gap: 36px;
    margin: 36px;
    justify-content: center;

    @media (max-width: $screen-md) {
        gap: 6px;
        margin: 30px 0;
    }
}

.btn-store-outlined {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    padding: 0.8rem;
    border-radius: 8px;
    text-decoration: none;
    border: 1px solid $color-neutral-deep;
    transition: all 0.2s ease-in-out;
}

.btn-store-outlined:hover {
    background: #f8f8f8;
}
</style>
