<template>
    <div>
        <BreadCrumb />
        <MobileAppAndAdapter class="mobile-app-and-adapter-page" />
        <Faq
            :active="7"
            :static="true"
            :faqList="adapterFaq"
            :title="adapter?.headings?.h4[1]"
            :showButtons="false"
        />
        <PromoSection
            :title="promoDetails?.titleFormatted"
            :buttonTitle="promoDetails?.buttonTitle"
            :buttonSubTitle="promoDetails?.buttonSubTitle"
            :shouldOpenVendorModal="promoDetails?.shouldOpenVendorModal"
            :fbEvent="eventName"
        />
    </div>
</template>

<script setup>
import MobileAppAndAdapter from '../components/adapter/MobileAppAndAdapter';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';
import PromoSection from '@/components/PromoSection.vue';
import Faq from '@/components/Faq';
import { computed, onBeforeUnmount, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTranslation } from 'i18next-vue';
import { createMetaInfo } from '@/utils/helpers';
import { useHead } from '@unhead/vue';
import appConfig from '@/config/app.config';
import { formatPhraseWithHtml } from '@/utils/helpers';
import { FAQ_CATEGORY_ID } from '@/utils/constants';

const store = useStore();
const route = useRoute();
const { t } = useTranslation(['shared']);
const langCode = computed(() => store.state.language.lang?.code);

onMounted(() => {
    document.body.classList.remove('overflow-hidden');
    store.dispatch("faq/getFaq", { lang: langCode.value, categoryId: FAQ_CATEGORY_ID.PRICING_PAGE });
});

const pricing = computed(() => store.state.pages?.pricing);

const pricingData = computed(() => {
    const childrenArr = pricing.value?.children;

    if (!Array.isArray(childrenArr)) {
        return;
    }

    if (route.name === 'pricing-evo') {
        return childrenArr.find(el => el.tag === 'pricing_evo');
    }

    return childrenArr.find(el => el.tag === 'pricing');
});

const adapterFaq = computed(() => store.state.faq.faq[FAQ_CATEGORY_ID.PRICING_PAGE - 1]);
const adapter = computed(() => store.state.pages?.adapter);

const isInUK = computed(() => store.state.prices?.prices?.countryCode === 'GB');
const isInDE = computed(() => store.state.prices?.prices?.countryCode === 'DE');
const isSaleEnabled = appConfig.saleEnabled;

const promoDetails = computed(() => {
    const isPromoActive = isInUK.value && isSaleEnabled;

    const title = isPromoActive
        ? 'shared_unlock-FREE-lifetime-carista-app-diagnostics'
        : 'shared_evolve-your-driving-experience';

    const gradientPhrase = isPromoActive
        ? 'shared_unlock-FREE-lifetime-carista-app-diagnostics-gradient'
        : 'shared_evolve-your-driving-experience-gradient';

    const buttonTitle = 'shared_get-carista-evo';
    const buttonSubTitle = isPromoActive
        ? 'shared_enjoy-free-diagnostics'
        : 'shared_enjoy-your-ride';

    const gradientClass = 'text-gradient';

    return {
        titleFormatted: formatPhraseWithHtml(
            t(title),
            t(gradientPhrase),
            'span',
            gradientClass
        ),
        buttonTitle: t(buttonTitle),
        buttonSubTitle: t(buttonSubTitle),
        shouldOpenVendorModal: true,
    };
});

const eventName = computed(() => {
    const name = 'get_started_pricing';
    return route.name == 'pricing-evo' ? name + '_evo' : name;
})

useHead(() => {
    return createMetaInfo({
        title: pricingData.value?.metaTitle,
        pageMeta: pricingData.value,
    });
});

</script>
