<template>
    <div class="ratings-section container">
        <div class="title">{{ t('shared_trusted-by-over-1M-users') }}</div>
        <div class="ratings-container">
            <a
                v-for="(rating, index) in ratings"
                :key="index + 'ratings'"
                :href="rating.link"
                target="_blank"
            >
                <div class="rating">{{ rating.score }}<StarIcon /></div>
                <div class="store">
                    <component :is="rating.iconComponent" />{{
                        t(rating.textKey)
                    }}
                </div>
            </a>
        </div>
    </div>
</template>

<script setup>
import { useTranslation } from 'i18next-vue';
import StarIcon from '@/assets/icons/StarIcon';
import AppleLogoIcon from '@/assets/icons/AppleLogoIcon';
import GooglePlayColorIcon from '@/assets/icons/GooglePlayColorIcon';
import AmazonIcon from '@/assets/icons/AmazonIcon';
import { STORE_LINKS } from '@/utils/constants';

const { t } = useTranslation(['shared']);

const ratings = [
    {
        link: STORE_LINKS.APP_STORE,
        score: '4.7',
        iconComponent: AppleLogoIcon,
        textKey: 'App Store',
    },
    {
        link: STORE_LINKS.GOOGLE_PLAY,
        score: '4.6',
        iconComponent: GooglePlayColorIcon,
        textKey: 'Google Play',
    },
    {
        link: STORE_LINKS.AMAZON,
        score: '4.4',
        iconComponent: AmazonIcon,
        textKey: 'Amazon',
    },
];
</script>

<style lang="scss" scoped>
.ratings-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 60px;

      @media (max-width: $screen-md) {
        align-items: flex-start;
		    padding-top: 30px;
      }

      .title {
        font-weight: 500;
        font-size: 40px;
        margin-bottom: 30px;

        @media (max-width: $screen-md) {
          font-size: 32px;
        }
      }

      .ratings-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;

        @media(max-width:$screen-md) {
          flex-wrap: wrap;
        }

        a {
          .rating {
            font-weight: 500;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
          }

          .store {
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
</style>
