import { createRouter, createWebHistory } from "vue-router";
import { store } from "../store/store";
import { ALL_MAKES, LANGUAGES } from "../utils/constants";
import { getLangFromCountryCode } from "../utils/helpers";
import { storedLang } from "./i18n.config";
import i18next from "i18next";

import PublicLayout from "../views/Public.vue";
import DashboardLayout from "../views/Dashboard.vue";
import HomePage from "../views/HomePage.vue";
import SupportedCarsLayout from "../views/SupportedCarsLayout.vue";
import SupportedCarsPage from "../views/SupportedCarsPage.vue";
import SupportedCarsAllPage from "../views/SupportedCarsAllPage.vue";
import PricingPage from "../views/PricingPage.vue";
import AdaptersPage from "../views/AdaptersPage.vue";
import AppFeaturesPage from "../views/AppFeaturesPage.vue";
import ProductAppPage from "../views/ProductAppPage.vue";
import ContactUsPage from "../views/ContactUsPage.vue";
import BlogMainPage from "../views/BlogMainPage.vue";
import BlogInnerPage from "../views/BlogInnerPage.vue";
import AdapterDocuments from "../views/AdapterDocuments.vue";
import WebTerms from "../views/WebTerms.vue";
import WebLegal from "../views/WebLegal.vue";
import AppTerms from "../views/AppTerms.vue";
import AppPrivacy from "../views/AppPrivacy.vue";
import AppLegal from "../views/AppLegal.vue";
import Impressum from "../views/Impressum.vue";
import NotFound from "../views/NotFound.vue";
import AboutUs from "@/views/AboutUs.vue";
import VehicleSelectorForDistributors from "@/views/VehicleSelectorForDistributors.vue";

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  scrollBehavior(to, from) {
    const toLang = to.params.lang;
    const fromLang = from.params.lang;

    if (toLang && fromLang && toLang !== fromLang) { // Do not scroll when the user changes the language
      return false;
    }

    const brandChanged = to.params.make && to.params.make !== from.params.make;
    const modelChanged = to.params.model && to.params.model !== from.params.model;

    const isFromSupportedCarsPage = from.name === 'supported-cars' || from.name === 'make';

    if ((brandChanged || modelChanged) && isFromSupportedCarsPage) { // Do not scroll when the user uses the vehicle selector
      return false;
    }

    document.getElementById("app").scrollIntoView({ behavior: "auto" });
  },
  routes: [
    {
      path: "/dashboard",
      name: "dashboard",
      redirect: "/account",
      component: DashboardLayout,
      children: [
        // {
        //   path: '/account',
        //   name: 'account',
        //   component: () => import(/* webpackChunkName: "Overview" */'../components/DashboardAccount.vue'),
        //   meta: { authorize: ['logged-client'] },
        // },
      ],
    },
    {
      path: "/:lang/custom-vehicle-selector/:uuid",
      name: "custom-vehicle-selector",
      meta: {
        breadcrumb: "custom-vehicle-selector",
        hideAdditionalComponents: true,
      },
      component: VehicleSelectorForDistributors,
    },
    {
      path: "/",
      component: PublicLayout,
      meta: {
        breadcrumb: "home",
      },
      children: [
        {
          path: "/",
        },
        {
          path: "/:lang",
          name: "home",
          meta: {
            reload: true,
            breadcrumb: "home",
          },
          component: HomePage,
        },
        {
          path: "/:lang/supported-cars",
          meta: {
            reload: false,
            breadcrumb: "supported-cars",
          },
          component: SupportedCarsLayout,
          children: [
            {
              path: "",
              name: "supported-cars",
              meta: {
                reload: false,
              },
              component: SupportedCarsPage,
            },
            {
              path: "all",
              name: "supported-cars-all",
              meta: {
                reload: false,
                breadcrumb: "supported-cars-all",
              },
              component: SupportedCarsAllPage,
            },
            {
              path: ":make",
              name: "make",
              meta: {
                reload: false,
              },
              component: SupportedCarsPage,
              children: [
                {
                  path: ":model",
                  name: "model",
                  meta: {
                    reload: false,
                  },
                  component: SupportedCarsPage,
                  children: [
                    {
                      path: ":generation",
                      meta: {
                        reload: false,
                      },
                      name: "generation",
                      component: SupportedCarsPage,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "/:lang/pricing",
          name: "pricing",
          meta: {
            reload: false,
            breadcrumb: "pricing",
          },
          component: PricingPage,
        },
        {
          path: "/:lang/pricing-evo",
          name: "pricing-evo",
          meta: {
            reload: false,
            breadcrumb: "pricing-evo",
          },
          component: PricingPage,
        },
        {
          path: "/:lang/scanners",
          name: "scanners",
          meta: {
            reload: false,
            breadcrumb: "scanners",
          },
          component: AdaptersPage,
        },
        {
          path: "/:lang/features",
          name: "features",
          meta: {
            reload: false,
            breadcrumb: "features",
          },
          component: AppFeaturesPage,
        },
        {
          path: "/:lang/app",
          name: "app",
          meta: {
            reload: false,
            breadcrumb: "app",
          },
          component: ProductAppPage,
        },
        {
          path: "/:lang/contact-us",
          name: "contact-us",
          meta: {
            reload: false,
            breadcrumb: "contact-us",
          },
          component: ContactUsPage ,
        },
        {
          path: "/:lang/blog",
          name: "blog",
          meta: {
            reload: false,
            breadcrumb: "blog",
          },
          component: BlogMainPage ,
            children: [
              {
                path: ":slug",
                name: "slug",
                meta: {
                  reload: false,
                },
                component: BlogInnerPage ,
              }
            ],
        },
        {
          path: "/:lang/scanner-documents",
          name: "scanner-documents",
          meta: {
            breadcrumb: "scanner-documents",
          },
          component: AdapterDocuments ,
        },
        {
          path: "/:lang/web-terms",
          name: "web-terms",
          meta: {
            breadcrumb: "web-terms",
          },
          component: WebTerms ,
        },
        {
          path: "/:lang/web-legal",
          name: "web-legal",
          // meta: {
          //   breadcrumb: "web-legal",
          // },
          component: WebLegal ,
        },
        {
          path: "/:lang/app-terms",
          name: "app-terms",
          // meta: {
          //   breadcrumb: "app-terms",
          // },
          component: AppTerms ,
        },
        {
          path: "/:lang/app-privacy",
          name: "app-privacy",
          // meta: {
          //   breadcrumb: "app-privacy",
          // },
          component: AppPrivacy ,
        },
        {
          path: "/:lang/app-legal",
          name: "app-legal",
          // meta: {
          //   breadcrumb: "app-legal",
          // },
          component: AppLegal ,
        },
        {
          path: "/:lang/impressum",
          name: "impressum",
          meta: {
            breadcrumb: "impressum",
          },
          component: Impressum ,
        },
        {
          path: "/:lang/about-us",
          name: "about-us",
          meta: {
            reload: false,
            breadcrumb: "about-us",
          },
          component: AboutUs ,
        },
        {
          path: "/:lang/:pathMatch(.*)",
          meta: {
            breadcrumb: "404",
          },
          component: NotFound ,
        },
      ],
    }
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.hasOwnProperty("authorize")) {
    store.dispatch("profile/getProfile").then((res) => {
      const { authorize } = to.meta;
      const role = res.data.role.name;
      if (!authorize.includes(role)) {
        // console.log('no access!');
        return next({
          name: "home",
        });
      }
    });
  }

  let lang = storedLang;

  // navigate to language directory (at first landing)
  if (!LANGUAGES.includes(to.params.lang) && !storedLang) {
    await store.dispatch("geoIP/getIP");
    const countryCode = store.state.geoIP?.geoIP?.countryCode;
    lang = getLangFromCountryCode(countryCode.toLowerCase());
    store.dispatch("language/initLanguage", lang);
    i18next.changeLanguage(lang);
  }

  // create valid path when lang is missing
  let supportedLangPath = to.path;
  let langWasMissing = false;
  if (!LANGUAGES.includes(to.params.lang)) {
    langWasMissing = true;
    supportedLangPath = "/" + lang;
    if (to.path !== '/') supportedLangPath += to.path;
  }

  // create valid path when /supported-cars is missing before car brand
  const lowerCaseToPath = to.path?.toLowerCase();
  const makeInPath = ALL_MAKES.filter(make => lowerCaseToPath.includes("/" + make));
  if (makeInPath?.length && !to.path.includes("/supported-cars") && !to.path.includes("/blog")) {
    if (langWasMissing) {
      supportedLangPath = "/" + lang + "/supported-cars" + lowerCaseToPath;
    } else {
      supportedLangPath = "/" + lang + "/supported-cars" + lowerCaseToPath.slice(to.params.lang.length + 1);
    }
  }

  // if non-existent vehicle in the vehicle selector
  if (to.path.includes("/supported-cars") && to.params.make) {
    try {
      await store.dispatch("vehiclebrands/getDynamicVehiclesData", {
        make: to.params.make,
        model: to.params.model,
        generation: to.params.generation,
      });
    } catch (error) {
      supportedLangPath = `/${lang}/supported-cars`;
    }
  }

  // is it valid path
  if (supportedLangPath === to.path) {
    next();
  } else {
    // if path is not valid redirect with valid path
    next({
      path: supportedLangPath,
    });
  }

  const el = document.querySelector('meta[property="og:url"]');
  if (el) {
    el.content = document.location.href;
  }

  document.documentElement.setAttribute("lang", to.params.lang);
});

export default router;
