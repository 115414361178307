<template>
  <div
    @click.prevent="
      facebookGoogleAnalitycs(fbEvent ? fbEvent : 'get_now_click')
    "
    class="get-started-wrapper"
  >
    <button v-if="shouldOpenVendorModal" @click="openVendorModal" class="get-started" :class="className">
      <p>{{ title || t("shared_get-started") }}</p>
      <span v-if="subtitle">{{
        subtitle
      }}</span>
      <div class="get-started-svg-container">
        <BasicArrowRightIcon />
        <BasicArrowRightIcon />
      </div>
    </button>
    <a v-else-if="iFrameModeEnabled" @click="handleClick" :class="className" class="get-started" >
      <p>{{ title }}</p>
      <span v-if="subtitle">{{
        subtitle
      }}</span>
      <div class="get-started-svg-container">
        <BasicArrowRightIcon />
        <BasicArrowRightIcon />
      </div>
    </a>
    <router-link v-else :to="to" class="get-started" :class="className">
      <p>{{ title || t("shared_get-started") }}</p>
      <span v-if="subtitle">{{
        subtitle
      }}</span>
      <div class="get-started-svg-container">
        <BasicArrowRightIcon />
        <BasicArrowRightIcon />
      </div>
    </router-link>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useTranslation } from "i18next-vue";
import BasicArrowRightIcon from "@/assets/icons/BasicArrowRightIcon.vue";
import { facebookGoogleAnalitycs, isValidUrl } from "@/utils/helpers";
import { ADAPTER_TYPE } from '@/utils/constants.js';
import { useStore } from 'vuex';

const store = useStore();
const { t } = useTranslation(['shared']);

const props = defineProps({
  className: String,
  title: String,
  subtitle: String,
  to: [String, Object],
  shouldOpenVendorModal: {
    type: Boolean,
    default: false,
  },
  iFrameModeEnabled: {
    type: Boolean,
    default: false,
  },
  distributorLink: String,
  fbEvent: String,
  fbEventParam: String,
});

const prices = computed(() => store.state.prices?.prices);

const openVendorModal = () => {
  const siteUrl = prices.value.vendors[0]?.evoSiteUrl;

  if (prices?.value.vendors.length <= 1) {
    window.open(siteUrl);
  } else {
    store.dispatch("modals/openModal", {
      currentModal: "isVendorModalOpen",
      data: ADAPTER_TYPE.EVO,
    });
  }
};

const handleClick = () => {
  if (props.iFrameModeEnabled) {
    if (isValidUrl(props.distributorLink)) {
      window.top.location.href = props.distributorLink;
    }
    return;
  }
}

</script>

<style lang="scss">
  button {
    border: none;
    background-color: transparent;
  }
</style>
