<template>
  <div class="footer" id="footer">
    <div class="container">
      <nav class="footer-navigation">
        <ul class="container__list supported-cars">
          <li class="container__list__title">
            {{ t("menus_supported-cars") }}
          </li>
          <li class="container__list__item">
            <router-link :to="`/${langCode}/supported-cars`">{{
              t("menus_supported-cars")
            }}</router-link>
          </li>
          <li class="container__list__item">
            <router-link :to="`/${langCode}/supported-cars/all`">
              {{ t("menus_all-supported-cars") }}
            </router-link>
          </li>
        </ul>
        <ul class="container__list support-and-resources">
          <li class="container__list__title">
            <a>{{ t("menus_support-and-resources") }}</a>
          </li>
          <li class="container__list__item">
            <a :href="$appConfig.caristaFaqUrl" target="_blank">{{
              t("menus_help-center")
            }}</a>
          </li>
          <li class="container__list__item">
            <router-link
              class="dropdown-item__link"
              :to="`/${langCode}/blog`"
              @click.native="setOpenMenuMobile(false)"
            >
              {{ t("menus_blog") }}
            </router-link>
          </li>
          <li class="container__list__item">
            <router-link
              class="dropdown-item__link"
              :to="`/${langCode}/scanner-documents`"
              @click="setOpenMenuMobile(false)"
            >
              {{ t("menus_scanner-documents") }}
            </router-link>
          </li>
        </ul>
        <ul class="container__list company">
          <li class="container__list__title">{{ t('menus_company') }}</li>
          <li class="container__list__item">
            <router-link :to="`/${langCode}/about-us`">{{ t('menus_about-us') }}</router-link>
          </li>
          <li class="container__list__item">
            <router-link :to="`/${langCode}/contact-us`">{{ t('menus_contact-us') }}</router-link>
          </li>
        </ul>
        <ul class="container__list products">
          <li class="container__list__title">{{ t("menus_products") }}</li>
          <li class="container__list__item">
            <router-link :to="`/${langCode}/scanners`">
              {{ t("menus_scanners") }}
              <SaleTag v-if="campaignText && $appConfig.ENABLE_CAMPAIGNS" :text="campaignText" />
            </router-link>
          </li>
          <li class="container__list__item">
            <router-link :to="`/${langCode}/app`">{{
              t("menus_app")
            }}</router-link>
          </li>
          <li
            @click="facebookGoogleAnalitycs('pricing_button', {
              button_position: 'footer',
            });"
            class="container__list__item">
            <router-link :to="`/${langCode}/pricing-evo`">
              {{ t("menus_pricing") }}
            </router-link>
          </li>
          <li class="container__list__item">
            <router-link :to="`/${langCode}/features`">{{
              t("menus_app-features")
            }}</router-link>
          </li>
        </ul>

        <section class="social-wrapper">
          <ul class="container__list">
            <li class="container__list__title">{{ t("menus_socials") }}</li>
            <li class="container__list__socials-media">
              <a
                v-if="socialsLinkFacebook"
                :href="socialsLinkFacebook"
                target="_blank"
                rel="nofollow"
                @click="
                    facebookGoogleAnalitycs('social_media_click', {
                      channel: 'facebook',
                    })
                  "
              >
                <FacebookIcon />
                <span class="hide-on-mobile">Facebook</span>
              </a>

              <a
                v-if="socialsLinkLinkedin"
                :href="socialsLinkLinkedin"
                target="_blank"
                rel="nofollow"
                @click="
                    facebookGoogleAnalitycs('social_media_click', {
                      channel: 'linkedin',
                    })
                  "
              >
                <LinkedInIcon />
                <span class="hide-on-mobile">Linkedin</span>
              </a>

              <a
                v-if="socialsLinkInstagram"
                :href="socialsLinkInstagram"
                target="_blank"
                rel="nofollow"
                @click="
                    facebookGoogleAnalitycs('social_media_click', {
                      channel: 'instagram',
                    })
                  "
              >
                <InstagramIcon />
                <span class="hide-on-mobile">Instagram</span>
              </a>

              <a
                v-if="socialsLinkTiktok"
                :href="socialsLinkTiktok"
                target="_blank"
                rel="nofollow"
                @click="
                    facebookGoogleAnalitycs('social_media_click', {
                      channel: 'tiktok',
                    })
                  "
              >
                <TikTokIcon />
                <span class="hide-on-mobile">TikTok</span>
              </a>

              <a
                v-if="socialsLinkYoutube"
                :href="socialsLinkYoutube"
                target="_blank"
                rel="nofollow"
              >
                <YouTubeIcon />
                <span class="hide-on-mobile">Youtube</span>
              </a>
            </li>
          </ul>
        </section>
        <section class="feedback-group">
          <p class="feedback-group__label">
            <span class="hide-on-mobile">{{
              t("menus_wanna-say-something-about-us")
            }}</span>
            <span class="show-on-mobile"
              >
              {{ t("menus_wanna-say-something-about-us") }}</span
            >
          </p>
          <a
            :href="$appConfig.caristaFeedBackUrl"
            target="_blank"
            class="btn-feedback-outlined lighter"
          >
            {{ t("menus_give-us-feedback") }}<ArrowRightIcon />
          </a>
        </section>

        <section class="carista-app-group">
          <p class="carista-app-group__label">
            {{ t("menus_download-carista-app") }}
          </p>
          <div
            class="carista-app-group__store"
          >
            <a
              :href="$appConfig.appleAppUrl"
              target="_blank"
              class="btn-store-outlined"
              @click="facebookGoogleAnalitycs('app_store_click', {
                button_position: 'footer',
                platform: 'App Store'
              })"
            >
              <AppStoreIcon />
            </a>

            <a
              :href="$appConfig.googleAppUrl"
              target="_blank"
              class="btn-store-outlined"
              @click="facebookGoogleAnalitycs('app_store_click', {
                button_position: 'footer',
                platform: 'Google Play'
              })"
            >
              <GoogePlayIcon />
            </a>
          </div>
        </section>
        <CaristaWhiteLogoIcon class="footer-logo" />
        <section class="copyrits-wrapper">
          <p class="copyright">
            {{ copyright }}<br class="hide-on-mobile"/>
            {{ t("menus_all-rights-reserved") }}
          </p>
        </section>

        <section class="terms-wrapper">
          <router-link :to="`/${langCode}/web-terms`"
            ><span>{{ t("menus_web-terms") }}</span></router-link
          >
          <router-link :to="`/${langCode}/app-privacy`"
            ><span>{{ t("menus_web-privacy") }}</span></router-link
          >
          <router-link :to="`/${langCode}/impressum`"
            ><span>{{ t("menus_impressum") }}</span></router-link
          >
        </section>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { validateEmail, facebookGoogleAnalitycs } from "@/utils/helpers";
import Subscribe from "@/services/subscribe.service";
import CaristaWhiteLogoIcon from "@/assets/icons/CaristaWhiteLogoIcon.vue";
import FacebookIcon from "../assets/icons/FacebookIcon.vue";
import LinkedInIcon from "../assets/icons/LinkedInIcon.vue";
import InstagramIcon from "../assets/icons/InstagramIcon.vue";
import TikTokIcon from "../assets/icons/TikTokIcon.vue";
import YouTubeIcon from "../assets/icons/YouTubeIcon.vue";
import ArrowRightIcon from "../assets/icons/supportedCars/ArrowRightIcon.vue";
import AppStoreIcon from "../assets/icons/AppStoreIcon.vue";
import GoogePlayIcon from "../assets/icons/GoogePlayIcon.vue";
import ArrowRightExtended from "@/assets/icons/ArrowRightExtended.vue";
import { useTranslation } from "i18next-vue";
import SaleTag from './SaleTag.vue';
import {
  COPYRIGHT,
  SOCIALS_LINK_FACEBOOK,
  SOCIALS_LINK_LINKEDIN,
  SOCIALS_LINK_YOUTUBE,
  SOCIALS_LINK_TIKTOK,
  SOCIALS_LINK_INSTAGRAM
} from "@/utils/constants";

const store = useStore();
const { t } = useTranslation(['menus', 'shared', 'translation']);
const email = ref("");
const loading = ref(false);

const copyright = ref(COPYRIGHT);
const socialsLinkFacebook = ref(SOCIALS_LINK_FACEBOOK);
const socialsLinkLinkedin = ref(SOCIALS_LINK_LINKEDIN);
const socialsLinkInstagram = ref(SOCIALS_LINK_INSTAGRAM);
const socialsLinkTiktok = ref(SOCIALS_LINK_TIKTOK);
const socialsLinkYoutube = ref(SOCIALS_LINK_YOUTUBE);

const langCode = computed(() => store.state.language.lang?.code);
const campaignText = computed(() => store.state.campaign?.tags?.footer);
const campaignUrl = computed(() => store.state.campaign?.tags?.headerAndFooterUrl); // To be used for dynamic URL from the CMS
const isInUK = computed(() => store.state.prices?.prices?.countryCode === 'GB');

function joinNewsLetter() {
  if (validateEmail(email.value)) {
    loading.value = true;
    const formData = new FormData();
    formData.append("EMAIL", email.value);
    formData.append("subscribe", "Subscribe");
    formData.append("u", "d94cc1110fc324e7b578c6acd");

    Subscribe.subscribe(formData)
      .then(() => {
        email.value = "";
        facebookGoogleAnalitycs("newsletter_subscription", { state: { email: email.value } });
        openNotificationModal({
          showSuccess: true,
          titleTxt: t("shared_thank-you"),
          descriptionTxt: t("shared_email-successfully-submitted"),
          btnTxt: t('translation_ok')
        });
      })
      .catch(() => {
        facebookGoogleAnalitycs("newsletter_subscription", { state: { email: email.value } });
        openNotificationModal({
          showSuccess: false,
          titleTxt: t("shared_something-went-wrong"),
          descriptionTxt: t("shared_review-email-and-try-again"),
          btnTxt: t('shared_try-again')
        });
      })
      .finally(() => {
        loading.value = false;
      });
  } else {
    openNotificationModal({
      showSuccess: false,
      titleTxt: t("shared_something-went-wrong"),
      descriptionTxt: t("shared_review-email-and-try-again"),
      btnTxt: t('shared_try-again')
    });
  }
}

function openNotificationModal(option) {
  store.dispatch("modals/openModal", {
    currentModal: "isNotificationModalOpen",
    data: option,
  });
}
</script>
