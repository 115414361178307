<template>
    <section class="showcase">
        <h2 class="showcase-title">
            {{ content.title }}
        </h2>
        <div class="showcase-container">
            <ShowcaseCard
                :title="content.productOne.title"
                :image="content.productOne.image"
                class="showcase-card-wrapper"
            >
                <AppStoreLinks
                    :eventName="'app_store_click_about_us'"
                    :prices
                    class="buttons-wrapper"
                />
            </ShowcaseCard>
            <ShowcaseCard
                :title="content.productTwo.title"
                :image="content.productTwo.image"
                class="showcase-card-wrapper"
            >
                <ActionButton
                    @click="handleClick"
                    :customText="t('shared_buy-now')"
                    :isLink="isSingleVendor"
                    :linkUrl="
                        isSingleVendor
                            ? prices.value?.vendors[0]?.evoSiteUrl
                            : ''
                    "
                    :isHighlighted="false"
                    class="action-button"
                    linkTarget="_blank"
                />
            </ShowcaseCard>
        </div>
    </section>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from 'i18next-vue';
import ShowcaseCard from '@/components/ShowcaseCard.vue';
import AppStoreLinks from './AppStoreLinks.vue';
import ActionButton from './UI-elements/ActionButton.vue';
import { ADAPTER_TYPE } from '@/utils/constants';
import { facebookGoogleAnalitycs } from '@/utils/helpers';
defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const store = useStore();
const { t } = useTranslation(['shared']);

const prices = computed(() => {
    return store.state.prices?.prices;
});

const isSingleVendor = computed(() => {
    if (prices.value?.vendors?.length == 1) {
        return prices.value.vendors[0]?.evoSiteUrl;
    }

    return false;
});

const redirectToVendor = (url) => {
    const shouldOpenNewTab = !/shop.+carista.com|2ly.link./.test(url);
    window.open(url, shouldOpenNewTab ? '_blank' : '_self');
};

const handleClick = () => {
    const siteUrl = prices.value.vendors[0]?.evoSiteUrl;

    facebookGoogleAnalitycs('buy_now_about_us');

    if (prices.value?.vendors?.length <= 1) {
        // emitAnalyticsEvent(eventName, siteUrl, prices.value.vendors[0]?.siteName, true, position);
        redirectToVendor(siteUrl);

        return;
    }

    showModal(ADAPTER_TYPE.EVO);
};

const showModal = (adapterType) => {
    store.dispatch('modals/openModal', {
        currentModal: 'isVendorModalOpen',
        data: adapterType || true,
    });
};
</script>

<style lang="scss" scoped>
.showcase {
    text-align: center;
    background-color: $color-white;
    padding: 150px 24px;
}

.showcase-title {
    font-size: 56px;
    margin-bottom: 78px;

    @media (max-width: $screen-md) {
        font-size: 36px;
    }
}

.showcase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: auto;

    @media (max-width: $screen-md) {
        gap: 42px;
    }
}

.action-button {
    margin: 36px auto;
}

@media (min-width: $screen-md) {
    .showcase-container {
        flex-direction: row;
        justify-content: center;
        gap: 3rem;
    }
}
</style>
