<template>
  <div class="container-brands-wrapper section">
    <div class="banner hide-on-mobile">
      <div class="partner">{{ t("shared_vw-group-partner") }}</div>
      <div class="sfd-unlock">{{ t("shared_sfd-unlock") }}</div>
    </div>
    <section class="container container-brands">
      <h2>
        {{ app?.headings.h2[1] }}<br class="hide-on-mobile" />
        {{ app?.headings.h2[2] }}
      </h2>
      <div class="banner show-on-mobile">
        <div class="partner">{{ t("shared_vw-group-partner") }}</div>
        <div class="sfd-unlock">{{ t("shared_sfd-unlock") }}</div>
      </div>
      <div class="brand-list-wrapper">
        <ul class="brands-list-flexbox">
          <template
            v-for="brand in brands"
            :key="brand.id"
          >
            <li
              @click="checkForCampability(brand)"
            >
              <component :is="defineAsyncComponent(Icons.getIcon(brand.title + 'Logo'))"></component>
              {{ brand.title }}
            </li>
          </template>
        </ul>
        <div class="brand-list-button-wrapper">
          <p>
            {{
              t("shared_exact-features-availability-depends-on-your-vehicle")
            }}
          </p>
          <router-link
            :to="`/${langCode}/supported-cars`"
            class="btn-feedback-outlined"
            >{{ t("shared_check-car-compatibility") }}<ArrowRightIcon/>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { computed, onMounted, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useTranslation } from "i18next-vue";
import ArrowRightIcon from "../../assets/icons/supportedCars/ArrowRightIcon.vue";
import Icons from "@/utils/icons";

const store = useStore();
const router = useRouter();
const { t } = useTranslation(['shared']);

const brands = computed(() => store.state.vehiclebrands.brands);
const langCode = computed(() => store.state.language.lang?.code);
const app = computed(() => store.state.pages?.app);

function checkForCampability(brand) {
  router.push(`/${langCode.value}/supported-cars/${brand.id}`);
}

onMounted(() => {
  if (brands.value?.length) {
    return;
  }

  store.dispatch("vehiclebrands/getBrands");
});
</script>
