<template>
    <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.17281 1.09198C8.02485 0.79586 7.71218 0.607422 7.36881 0.607422C7.02543 0.607422 6.71555 0.79586 6.5648 1.09198L4.76976 4.65346L0.760911 5.22415C0.42591 5.27261 0.146743 5.49874 0.0434509 5.80831C-0.0598411 6.11789 0.0239091 6.45977 0.263993 6.68859L3.17292 9.46401L2.48617 13.3862C2.43033 13.7093 2.56992 14.0377 2.84629 14.2288C3.12267 14.4199 3.48838 14.4442 3.78988 14.2907L7.3716 12.4467L10.9533 14.2907C11.2548 14.4442 11.6205 14.4226 11.8969 14.2288C12.1733 14.035 12.3129 13.7093 12.257 13.3862L11.5675 9.46401L14.4764 6.68859C14.7165 6.45977 14.803 6.11789 14.697 5.80831C14.5909 5.49874 14.3145 5.27261 13.9795 5.22415L9.96786 4.65346L8.17281 1.09198Z"
            fill="#FED53B"
        />
    </svg>
</template>
