<template>
  <div
    class="homepage"
    :class="{ white: active === 2 || active === 5 || active === 6 }"
  >
    <IntroEffect />
    <HomePageIntro
      :active="active"
      :imagePhone="isMobile ? home?.children[1]?.images[0]?.url : home?.children[0]?.images[0]?.url"
      :imagePhoneAlt="home?.children[0]?.images[0]?.imageMeta?.metaAltDescription"
      :imagePhoneTitle="home?.children[0]?.images[0]?.imageMeta?.metaTitle"
      :imageAdapter="home?.children[0]?.images[1]?.url"
      :imageAdapterAlt="home?.children[0]?.images[1]?.imageMeta?.metaAltDescription"
      :imageAdapterTitle="home?.children[0]?.images[1]?.imageMeta?.metaTitle"
      :show="show"
      :h1text="home?.headings?.h1[0]"
      :h2text="home?.headings?.h2[0]"
      id="homepageIntro"
    />
    <HomePageTools :active="active" id="homepageTools" />

    <div class="homepage__app-and-adapter">
      <div class="images">
        <img
          :src="home?.children[2]?.images[0]?.url"
          :class="{ visible: active <= 3 }"
          :alt="home?.children[2]?.images[0]?.imageMeta?.metaAltDescription"
          :title="home?.children[2]?.images[0]?.imageMeta?.metaTitle"
          loading="lazy"
        />
        <img
          :src="home?.children[2]?.images[1]?.url"
          :class="{ visible: active >= 4 }"
          :alt="home?.children[2]?.images[1]?.imageMeta?.metaAltDescription"
          :title="home?.children[2]?.images[1]?.imageMeta?.metaTitle"
          loading="lazy"
        />
      </div>
      <HomePageApp :active="active" id="homepageApp" />
      <HomePageAdapter :active="active" id="homepageAdapter" />
    </div>

    <HowItWorks
      :active="active"
      :activeStep="activeStep"
      @changeActiveStep="changeActiveStep"
      id="homepageHowItWorks"
    />
    <VueperSlider
      :title="home?.headings?.h4[0]"
      :icons="homePageTestimonials"
      :active="active"
      id="homepageTestimonials"
      ref="testimonials"
      :testimonials="homeTestimonials"
    />
    <Faq :active="active" id="homepageFaq" :faqList="homeFaq" :title="home?.headings?.h4[1]" />
    <PromoSection
    :shouldOpenVendorModal="true"
      fbEventParam="home page down"
    />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch, onBeforeUnmount, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import HomePageIntro from "../components/homepage/HomePageIntro";
import HomePageTools from "../components/homepage/HomePageTools";
import HomePageApp from "../components/homepage/HomePageApp";
import HomePageAdapter from "../components/homepage/HomePageAdapter";
import HowItWorks from "../components/HowItWorks";
import VueperSlider from "../components/VueperSlider";
import Faq from "../components/Faq";
import IntroEffect from "../components/IntroEffect";
import { createMetaInfo, mapLangFullNameToCode } from "@/utils/helpers";
import { useHead } from '@unhead/vue';
import { TESTIMONIALS_CATEGORY_ID, FAQ_CATEGORY_ID } from '@/utils/constants';
import { homePageTestimonials } from '@/assets/data/testimonials';
import PromoSection from '@/components/PromoSection.vue';

const store = useStore();
const { t } = useTranslation(['shared']);

const active = ref(0);
const activeStep = ref(0);
const show = ref(false);
const sectionIntroOffset = ref(null);
const sectionToolsOffset = ref(null);
const sectionAppOffset = ref(null);
const sectionAdapterOffset = ref(null);
const sectionHowItWorksOffset = ref(null);
const sectionTestimonialsOffset = ref(null);
const sectionFaqOffset = ref(null);
const reducedConstant = ref(400);
const testimonials = ref(null);

const isMobile = computed(() => store.state.device.isMobile);

const loading = computed(() => store.state.loading);
const home = computed(() => store.state.pages?.homepage);
const homeFaqCategory = FAQ_CATEGORY_ID.HOME_PAGE;
const homeFaq = computed(() => store.state.faq?.faq[homeFaqCategory - 1]);
const homeTestimonialsCategory = TESTIMONIALS_CATEGORY_ID.HOME_PAGE;
const homeTestimonials = computed(
  () => store.state.testimonials?.testimonials[homeTestimonialsCategory - 1]
);
const langCode = computed(() => store.state.language.lang?.code);

const changeActiveStep = ($event) => {
  activeStep.value = $event;
};

// Changing active step in HowItWorks components based on scrolling
const calcActiveStep = () => {
  const stepHeight = (sectionTestimonialsOffset.value - sectionHowItWorksOffset.value) / 3;
  if (window.top.scrollY < sectionHowItWorksOffset.value + stepHeight - 400) {
    activeStep.value = 1;
  } else if (
    window.top.scrollY > sectionHowItWorksOffset.value + stepHeight - 400 &&
    window.top.scrollY < sectionHowItWorksOffset.value + stepHeight * 2 - 700
  ) {
    activeStep.value = 2;
  } else if (
    window.top.scrollY >
    sectionHowItWorksOffset.value + stepHeight * 2 - 700
  ) {
    activeStep.value = 3;
  }
};

const calcOffsets = () => {
  if (!loading.value) {
    // Select and get all sections offsets for visual animations
    const sectionIntro = document.querySelector("#homepageIntro");
    const sectionTools = document.querySelector("#homepageTools");
    const sectionApp = document.querySelector("#homepageApp");
    const sectionAdapter = document.querySelector("#homepageAdapter");
    const sectionHowItWorks = document.querySelector("#homepageHowItWorks");
    const sectionTestimonials = document.querySelector("#homepageTestimonials");
    const sectionFaq = document.querySelector("#homepageFaq");

    sectionIntroOffset.value = sectionIntro.getBoundingClientRect().top + window.scrollY;
    sectionToolsOffset.value = sectionTools.getBoundingClientRect().top + window.scrollY;
    sectionAppOffset.value = sectionApp.getBoundingClientRect().top + window.scrollY;
    sectionAdapterOffset.value = sectionAdapter.getBoundingClientRect().top + window.scrollY;
    sectionHowItWorksOffset.value = sectionHowItWorks.getBoundingClientRect().top + window.scrollY;
    sectionTestimonialsOffset.value = sectionTestimonials.getBoundingClientRect().top + window.scrollY;
    sectionFaqOffset.value = sectionFaq.getBoundingClientRect().top + window.scrollY;

    window.addEventListener("scroll", handleScroll);
  }
};

// Switching active state between components for animation effects
const handleScroll = () => {
  if (
    window.top.scrollY < sectionToolsOffset.value - reducedConstant.value &&
    active.value > 0
  ) {
    active.value = 1;
  } else if (
    window.top.scrollY > sectionToolsOffset.value - reducedConstant.value &&
    window.top.scrollY < sectionAppOffset.value - reducedConstant.value
  ) {
    active.value = 2;
  } else if (
    window.top.scrollY > sectionAppOffset.value - reducedConstant.value &&
    window.top.scrollY < sectionAdapterOffset.value - reducedConstant.value * 2
  ) {
    active.value = 3;
  } else if (
    window.top.scrollY >
      sectionAdapterOffset.value - reducedConstant.value * 2 &&
    window.top.scrollY < sectionHowItWorksOffset.value - reducedConstant.value
  ) {
    active.value = 4;
  } else if (
    window.top.scrollY >
    sectionHowItWorksOffset.value - reducedConstant.value &&
    window.top.scrollY <
      sectionTestimonialsOffset.value - reducedConstant.value
  ) {
    active.value = 5;
    calcActiveStep();
  } else if (
    window.top.scrollY >
    sectionTestimonialsOffset.value - reducedConstant.value &&
    window.top.scrollY < sectionFaqOffset.value - reducedConstant.value
  ) {
    active.value = 6;
  } else if (
    window.top.scrollY >
    sectionFaqOffset.value - reducedConstant.value
  ) {
    active.value = 7;
  }
};

document.body.classList.add("overflow-hidden");

watch(loading, (newVal) => {
  if (!newVal) {
    calcOffsets();
    document.body.classList.remove("overflow-hidden");
  }
});
watch(langCode, (newVal) => {
  store.dispatch(
    "testimonials/getTestimonials", { lang: newVal, categoryId: homeTestimonialsCategory }
  );
  store.dispatch("faq/getFaq", { lang: newVal, categoryId: homeFaqCategory });
});

onBeforeMount(async () => {
  if (
    !homeTestimonials.value ||
    (homeTestimonials.value &&
      mapLangFullNameToCode(homeTestimonials?.value[0].language) !== langCode.value)
  ) {
    await store.dispatch(
      "testimonials/getTestimonials", { lang: langCode.value, categoryId: homeTestimonialsCategory }
    );
  }

  if (
    !homeFaq.value ||
    (homeFaq.value && mapLangFullNameToCode(homeFaq?.value[0].language) !== langCode.value)
  ) {
    await store.dispatch("faq/getFaq", { lang: langCode.value, categoryId: homeFaqCategory });
  }

  setTimeout(() => {
    active.value = 1;
  }, 3500);
  setTimeout(() => {
    show.value = true;
    document.body.classList.remove("overflow-hidden");
  }, 1400);
});

onMounted(() => {
  calcOffsets();
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

useHead(() => {
  return createMetaInfo({
    title: home.value?.metaTitle,
    pageMeta: home.value,
  });
});
</script>
