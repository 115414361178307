<template>
  <div v-if="features">
    <div class="page__features">
      <BreadCrumb class="text-white"></BreadCrumb>
      <div class="content blue page">
        <div class="container">
          <h1 class="title">
            {{ features?.headings?.h1[0] }}
          </h1>
          <h2 class="subtitle">
            {{ features?.headings?.h2[0] }}
          </h2>
          <div class="phoneRow">
            <div class="image">
              <img
                class="phone"
                :src="features?.children[0]?.images[0]?.url"
                :alt="features?.children[0]?.images[0]?.imageMeta?.metaAltDescription"
                :title="features?.children[0]?.images[0]?.imageMeta?.metaTitle"
                loading="lazy"
              />
            </div>
            <div class="options">
              <div class="item">
                <span class="icon"><ModifyIcon /></span>
                <span class="description">{{
                  t("page-app-features_modify-cars-settings")
                }}</span>
              </div>
              <div class="item">
                <span class="icon"><ScanIcon /></span>
                <span class="description">{{
                  t("page-app-features_scan-and-clear-fault-codes")
                }}</span>
              </div>
              <div class="item">
                <span class="icon"><PerformIcon /></span>
                <span class="description">{{
                  t("page-app-features_perform-maintenance")
                }}</span>
              </div>
              <div class="item">
                <span class="icon"><MonitorIcon /></span>
                <span class="description">{{
                  t("page-app-features_monitor-live-data")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page_features__main">
      <div class="container">
        <ul class="page__features__items-wrapper">
          <li class="page__features__items-wrapper__item">
            <div class="content">
              <p class="title">
                {{ features?.headings?.h3[0] }}
              </p>
              <p class="subtitle" v-html="t(`page-app-features_customize-description`)"></p>
            </div>
            <div class="images">
              <span class="image-box">
                <img
                  :src="features?.children[1].items[0]?.images[0].url"
                  class="phone"
                  :alt="features?.children[1].items[0]?.images[0].imageMeta?.metaAltDescription"
                  :title="features?.children[1].items[0]?.images[0].imageMeta?.metaTitle"
                  loading="lazy"
                />
              </span>
            </div>
          </li>
          <li class="page__features__items-wrapper__item">
            <div class="content">
              <p class="title">
                {{ features?.headings?.h3[1] }}
              </p>
              <p class="subtitle" v-html="t(`page-app-features_diagnose-description`)"></p>
            </div>
            <div class="images">
              <span class="image-box">
                <img
                  :src="features.children[1].items[1]?.images[0].url"
                  class="phone"
                  :alt="features.children[1].items[1]?.images[0].imageMeta?.metaAltDescription"
                  :title="features.children[1].items[1]?.images[0].imageMeta?.metaTitle"
                  loading="lazy"
                />
              </span>
            </div>
          </li>
          <li class="page__features__items-wrapper__item">
            <div class="content">
              <p class="title">
                {{ features?.headings?.h3[2] }}
              </p>
              <p class="subtitle" v-html="t(`page-app-features_service-description`)"></p>
            </div>
            <div class="images">
              <span class="image-box">
                <img
                  :src="features.children[1].items[2]?.images[0].url"
                  class="phone"
                  :alt="features.children[1].items[2]?.images[0].imageMeta?.metaAltDescription"
                  :title="features.children[1].items[2]?.images[0].imageMeta?.metaTitle"
                  loading="lazy"
                />
              </span>
            </div>
          </li>
          <li class="page__features__items-wrapper__item">
            <div class="content">
              <p class="title">
                {{ features.headings?.h3[3] }}
              </p>
              <p class="subtitle" v-html="t(`page-app-features_live-data-description`)"></p>
            </div>
            <div class="images">
              <span class="image-box">
                <img
                  :src="features.children[1].items[3]?.images[0].url"
                  class="phone"
                  :alt="features.children[1].items[3]?.images[0].imageMeta?.metaAltDescription"
                  :title="features.children[1].items[3]?.images[0].imageMeta?.metaTitle"
                  loading="lazy"
                />
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <PromoSection
    :shouldOpenVendorModal="true"
      class="features-embark"
      fbEventParam="features page"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import PromoSection from '@/components/PromoSection.vue';
import ModifyIcon from "../assets/icons/features-page/ModifyIcon";
import ScanIcon from "../assets/icons/features-page/ScanIcon";
import PerformIcon from "../assets/icons/features-page/PerformIcon";
import MonitorIcon from "../assets/icons/features-page/MonitorIcon";
import { createMetaInfo } from "@/utils/helpers";
import { useTranslation } from "i18next-vue";
import { useHead } from '@unhead/vue';

const store = useStore();
const { t } = useTranslation(['page-app-features', 'shared']);

const features = computed(() => store.state.pages.features);
document.body.classList.remove("overflow-hidden");

useHead(() => {
  return createMetaInfo({
    title: features.value?.metaTitle,
    pageMeta: features.value,
  });
});
</script>
