<template>
    <div class="accordion">
        <div
            v-for="(item, index) in items"
            :key="`accordion-item-${index}`"
            :class="{ dark: isDarkModeEnabled }"
            class="accordion-item"
        >
            <button
                type="button"
                :class="{ dark: isDarkModeEnabled }"
                class="accordion-question"
                @click="toggleItem(index)"
            >
                {{ item.question }}
                <ChevronIcon
                    :color="isDarkModeEnabled ? '#787F8C' : '#1B1E21'"
                    :stroke="isDarkModeEnabled ? '#787F8C' : '#1B1E21'"
                    :class="{
                        'chevron-rotated': isOpen(index),
                        dark: isDarkModeEnabled,
                    }"
                    class="chevron-icon"
                />
            </button>
            <Transition name="accordion">
                <div
                    v-if="isOpen(index)"
                    v-html="item.answer"
                    :class="{ dark: isDarkModeEnabled }"
                    class="accordion-answer"
                ></div>
            </Transition>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import ChevronIcon from '@/assets/icons/ChevronIcon.vue';

const props = defineProps({
    items: {
        type: Array,
        default: [],
    },
    isDarkModeEnabled: {
        type: Boolean,
        default: false,
    },
});

// Store an array of open item indexes (allowing multiple open items).
const openIndexes = ref([]);

function isOpen(index) {
    return openIndexes.value.includes(index);
}

function toggleItem(index) {
    if (isOpen(index)) {
        openIndexes.value = openIndexes.value.filter((i) => i !== index);
    } else {
        openIndexes.value.push(index);
    }
}
</script>

<style lang="scss" scoped>
.accordion {
    padding: 0.5rem;
}

.accordion-item {
    border-top: 1px solid $color-neutral-deep;

    &.dark {
        border-top: 1px solid $color-neutral-darker;
    }
}

.accordion-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px 0;

    color: $color-neutral-deep;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.36px;
    line-height: 42px;

    &.dark {
        color: $color-neutral-light;
    }

    @media (max-width: $screen-md) {
        font-size: 20px;
        line-height: 32px;
    }
}

.chevron-icon {
    transition: transform 0.3s ease;
    width: 20px;
    height: 20px;
    &.chevron-rotated {
        transform: rotate(180deg);
    }
}

.accordion-answer {
    color: $color-neutral-deep;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.05px;
    margin-bottom: 42px;

    &.dark {
        color: $color-neutral-light;
    }

    @media (max-width: $screen-md) {
        font-size: 18px;
        margin: 10px 0 30px;
    }
}

.accordion-enter-active,
.accordion-leave-active {
    transition: all 500ms ease;
    overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
    max-height: 0px;
    opacity: 0;
    margin-bottom: 0;
    margin-top: 0;
}

.accordion-enter-to,
.accordion-leave-from {
    max-height: 200px;
    opacity: 1;
    margin-bottom: 42px;
    @media (max-width: $screen-md) {
        margin-bottom: 30px;
    }
}
</style>
