<template>
    <section class="engagement-section">
        <div class="engagement-container">
            <EngagementCard
                :icon="CommunityIcon"
                :title="t('page-about-us_join-our-community')"
                :description="t('page-about-us_becomte-part-of-network')"
                :action-label="t('page-about-us_join-now')"
                :action-link="SOCIALS_LINK_FACEBOOK_GROUP"
            />
            <EngagementCard
                :icon="DialogIcon"
                :title="t('page-about-us_contact-us')"
                :description="
                    t('page-about-us_got-questions-we-are-here-to-help')
                "
                :action-label="t('page-about-us_drop-us-a-message')"
                :action-link="'/contact-us'"
                :useRouterLink="true"
            />
            <EngagementCard
                :icon="BlogIcon"
                :title="t('page-about-us_explore-our-blog')"
                :description="t('page-about-us_get-inspired-and-stay-informed')"
                :action-label="t('page-about-us_learn-more')"
                :action-link="'/blog'"
                :useRouterLink="true"
            />
        </div>
    </section>
</template>

<script setup>
import EngagementCard from './EngagementCard.vue';
import CommunityIcon from '@/assets/icons/CommunityIcon.vue';
import DialogIcon from '@/assets/icons/DialogIcon.vue';
import BlogIcon from '@/assets/icons/BlogIcon.vue';
import { useTranslation } from 'i18next-vue';
import { SOCIALS_LINK_FACEBOOK_GROUP } from '@/utils/constants';

const { t } = useTranslation(['page-about-us']);
</script>

<style lang="scss" scoped>
.engagement-section {
    display: flex;
    justify-content: center;
    padding: 78px 0 0;
    border-top: 2px solid $color-neutral-darker;
    margin: 128px 128px 0px;
}

.engagement-container {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    max-width: $container-max-width;
}

@media (max-width: $screen-md) {
    .engagement-section {
        margin: 158px 28px 0px;
    }
    .engagement-container {
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }
}
</style>
