<template>
    <div class="vehicle-selector-tool">
        <div class="search-container">
            <VehicleSearchBar
                :query="searchQuery"
                :searchMode="searchMode"
                @input="handleSearch"
                @click="searchMode = true"
                @clear="clearSearch"
            />
            <Transition name="search-results">
                <VehicleSearchResults
                    v-if="searchMode"
                    :results="searchResults"
                    :brands="brands"
                    :hasQuery="!!searchQuery"
                    @selectBrand="handleBrandSelect"
                    @selectModel="handleSearchModelSelect"
                />
            </Transition>
        </div>
        <Transition name="search-results">
            <div v-if="!searchMode" class="accordion">
                <VehicleDropdown
                    :value="selectedBrand"
                    :options="brands"
                    :disabled="false"
                    :showIcons="true"
                    :activate="currentStep == 'brand' && !isLoading"
                    @select="handleBrandSelect"
                    @toggle="handleToggle('brand')"
                    :label="'1. ' + t('page-supported-cars_choose-a-brand')"
                    class="accordion-item"
                />
                <VehicleDropdown
                    :value="selectedModel"
                    :options="models"
                    :disabled="!models.length"
                    :activate="currentStep == 'model'"
                    @select="handleModelSelect"
                    @toggle="handleToggle('model')"
                    :label="'2. ' + t('page-supported-cars_choose-a-model')"
                    class="accordion-item"
                />
                <VehicleDropdown
                    :value="selectedGeneration"
                    :options="generations"
                    :disabled="!generations.length"
                    :activate="currentStep == 'generation'"
                    @select="handleGenerationSelect"
                    @toggle="handleToggle('generation')"
                    :label="
                        '3. ' + t('page-supported-cars_choose-a-generation')
                    "
                    class="accordion-item"
                />
            </div>
        </Transition>
        <div class="buttons-container">
            <router-link
                @click="facebookGoogleAnalitycs('all_supported_cars')"
                :to="`/${langCode}/supported-cars/all`"
                class="button"
            >
                {{ t('page-supported-cars_all-supported-cars') }}
            </router-link>
            <router-link
                @click="facebookGoogleAnalitycs('pricing_supported_cars')"
                :to="`/${langCode}/pricing-evo`"
                class="button primary"
            >
                {{ t('page-supported-cars_pricing') }}
            </router-link>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useTranslation } from 'i18next-vue';
import VehicleSearchBar from './VehicleSearchBar.vue';
import VehicleSearchResults from './VehicleSearchResults.vue';
import VehicleDropdown from './VehicleDropdown.vue';
import { fetchVehicleOptions } from '@/services/vehiclebrands.service';
import {
    searchVehicles,
    pathToPricing,
    facebookGoogleAnalitycs,
} from '@/utils/helpers';
import { OBD_PHASEOUT_EXPERIMENT_COUNTRIES } from '@/utils/constants';
import appConfig from '@/config/app.config';

const searchMode = ref(false);
const searchQuery = ref('');

const selectedBrand = ref(null);
const selectedModel = ref(null);
const selectedGeneration = ref(null);

const brands = ref([]);
const models = ref([]);
const generations = ref([]);

const searchResults = ref([]);
const currentStep = ref('brand');
const isLoading = ref(true);

const store = useStore();
const route = useRoute();
const router = useRouter();

const isFromPhaseOutCountries = computed(() => {
    const countryCode = store.state.geoIP?.geoIP?.countryCode;

    return OBD_PHASEOUT_EXPERIMENT_COUNTRIES.includes(countryCode);
});

const loadBrands = async () => {
    brands.value = await fetchVehicleOptions({});
};

const loadModels = async (brand) => {
    models.value = await fetchVehicleOptions({ make: brand });
};

const loadGenerations = async (model) => {
    generations.value = await fetchVehicleOptions(
        {
            make: selectedBrand.value,
            model: model,
        },
        langCode.value
    );
};

const handleBrandSelect = async (brand) => {
    searchMode.value = false;
    selectedBrand.value = brand;
    selectedModel.value = null;
    selectedGeneration.value = null;

    models.value = [];
    generations.value = [];

    currentStep.value = 'model';
    await loadModels(brand);
    updateRoute();
};

const handleModelSelect = async (model) => {
    selectedModel.value = model;
    selectedGeneration.value = null;
    generations.value = [];

    currentStep.value = 'generation';
    await loadGenerations(model);
    updateRoute();
};

const handleGenerationSelect = async (generation) => {
    selectedGeneration.value = generation;

    facebookGoogleAnalitycs('vehicle_check', {
        brand: selectedBrand.value,
        model: selectedModel.value,
        generation: selectedGeneration.value,
    });

    const data = await fetchVehicleOptions(
        {
            make: selectedBrand.value,
            model: selectedModel.value,
            generation: selectedGeneration.value,
        },
        langCode.value
    );

    store.commit('vehiclebrands/dynamicVehiclesData', data.expected ?? data);

    const requiresEvoAdapter = data?.features?.adapter === 'evo';

    if (
        isFromPhaseOutCountries.value &&
        appConfig.OBD_PHASEOUT_EXPERIMENT_ENABLED
    ) {
        router.push(`/${langCode.value}/pricing-evo`);

        return;
    }

    router.push(
        pathToPricing(langCode.value, selectedBrand.value, requiresEvoAdapter)
    );
};

const handleSearch = async (query) => {
    searchQuery.value = query;
    const vehiclesArray = store.state.vehiclebrands.brandsAndModels;
    searchResults.value = searchVehicles(vehiclesArray, query);
};

const handleSearchModelSelect = async (brand, model) => {
    searchMode.value = false;
    selectedBrand.value = brand;
    await loadModels(brand);

    selectedModel.value = model;
    selectedGeneration.value = null;
    currentStep.value = 'generation';
    await loadGenerations(model);
    updateRoute();
};

const handleToggle = (step) => {
    if (
        step == 'brand' &&
        (currentStep.value == 'model' || currentStep.value == 'generation')
    ) {
        currentStep.value = step;
    }

    if (
        step == 'model' &&
        (currentStep.value == 'generation' || selectedBrand.value)
    ) {
        currentStep.value = step;
    }

    if (step == 'generation' && selectedModel.value) {
        currentStep.value = step;
    }
};

const clearSearch = () => {
    searchQuery.value = '';
    searchMode.value = false;
};

const updateRoute = () => {
    let routeName = 'supported-cars';

    const params = {};

    if (selectedBrand.value) {
        routeName = 'make';
        params.make = selectedBrand.value;
    }

    if (selectedModel.value) {
        routeName = 'model';
        params.model = selectedModel.value;
    }

    router.push({ name: routeName, params });
};

const { t } = useTranslation(['page-supported-cars']);
const langCode = computed(() => store.state.language.lang?.code);

onMounted(async () => {
    isLoading.value = true;
    await loadBrands();

    const { make, model } = route.params;

    if (make) {
        selectedBrand.value = make;
        await loadModels(make);
        currentStep.value = 'model';
    }

    if (model) {
        selectedModel.value = model;
        await loadGenerations(model);
        currentStep.value = 'generation';
    }

    isLoading.value = false;
});

watch(
    () => route.params,
    async () => {
        if (!route.params.make) {
            searchMode.value = false;
            selectedBrand.value = null;
            selectedModel.value = null;
            selectedGeneration.value = null;

            models.value = [];
            generations.value = [];

            currentStep.value = 'brand';
        }
    }
);
</script>

<style lang="scss" scoped>
.vehicle-selector-tool {
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;

    min-height: 600px;

    margin-top: 20px;

    @media (max-width: $screen-md) {
        margin-top: 0px;
        margin: 0px 24px;
    }
}

.search-container {
    background-color: $color-neutral-lightest;
    border-radius: 18px;

    @media (max-width: $screen-md) {
        width: 100%;
    }
}

.accordion {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 690px;

    @media (max-width: $screen-md) {
        width: 100%;
    }
}

.buttons-container {
    display: flex;
    justify-content: space-between;
    width: min(690px, 100%);
    margin: 12px 0px 50px;

    @media (max-width: $screen-md) {
        flex-direction: column-reverse;
        gap: 14px;
        margin-bottom: 66px;
    }
}

.button {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    padding: 14px 20px;
    color: $color-neutral-darkest;
    border-radius: 34px;
    border: 1px solid var(--color-neutral-light, #d1d4d7);

    @media (max-width: $screen-md) {
        justify-content: center;
    }

    &.primary {
        background-color: $color-primary-default;
        color: $color-white;
        border: none !important;
    }
}

.search-results-enter-active,
.search-results-leave-active {
    transition: all 400ms ease-in-out;
}

.search-results-enter-from,
.search-results-leave-to {
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
}
</style>
