<template>
    <!-- Render multiple lines based on the `lines` prop -->
        <div
            v-if="lines == 1"
            :class="[{ 'skeleton-loader--shimmer': isLoading }, skeletonClass]"
            :style="skeletonStyle"
        ></div>
        <template v-else>
            <div
                v-for="line in lines"
                :key="line"
                :class="[
                    { 'skeleton-loader--shimmer': isLoading },
                    skeletonClass,
                ]"
                :style="skeletonStyle"
            ></div>
        </template>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    width: {
        type: [String, Number],
        default: '100%',
    },
    height: {
        type: [String, Number],
        default: '14px',
    },
    borderRadius: {
        type: [String, Number],
        default: '4px',
    },
    darkMode: {
        type: Boolean,
        default: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    shimmer: {
        type: Boolean,
        default: true,
    },
    // NEW: number of lines
    lines: {
        type: Number,
        default: 1,
    },
});

const skeletonClass = computed(() => [
    'skeleton-loader',
    { 'skeleton-loader--shimmer': props.shimmer },
]);

const skeletonStyle = computed(() => {
    const widthValue =
        typeof props.width === 'number' ? `${props.width}px` : props.width;
    const heightValue =
        typeof props.height === 'number' ? `${props.height}px` : props.height;
    const borderRadiusValue =
        typeof props.borderRadius === 'number'
            ? `${props.borderRadius}px`
            : props.borderRadius;
    const backgroundColorValue = props.darkMode ? '#20263E' : '#e0e0e0';

    return {
        width: widthValue,
        height: heightValue,
        borderRadius: borderRadiusValue,
        backgroundColor: backgroundColorValue,
    };
});
</script>

<style scoped>
/* Basic skeleton loader styles */
.skeleton-loader + .skeleton-loader {
    /* Each line is a block-level element. If you need spacing,
       you can also handle it here or in the inline style above. */
    margin-top: 24px;
}

/* Shimmer effect */
.skeleton-loader--shimmer {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.skeleton-loader--shimmer::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
        to right,
        transparent 0%,
        rgba(255, 255, 255, 0.227) 50%,
        transparent 100%
    );
    animation: shimmer 1.5s;
    animation-iteration-count: 6;
}

@keyframes shimmer {
    0% {
        left: -50%;
    }
    100% {
        left: 100%;
    }
}
</style>
