<template>
    <div class="vehicle-search-bar">
        <SearchIconResponsive color="#787F8C" class="search-icon" />
        <input
            :value="query"
            @input="$emit('input', $event.target.value)"
            :placeholder="t('page-supported-cars_search-by-brand-or-model')"
            type="text"
        />
        <button v-if="searchMode" @click.stop="$emit('clear')">
            <XCloseIcon class="close-icon" />
        </button>
    </div>
</template>

<script setup>
import SearchIconResponsive from '@/assets/icons/SearchIconResponsive.vue';
import XCloseIcon from '@/assets/icons/XCloseIcon.vue';
import { useTranslation } from 'i18next-vue';

const props = defineProps({ query: String, searchMode: Boolean });
const emit = defineEmits(['input', 'clear']);
const { t } = useTranslation(['page-supported-cars']);
</script>

<style lang="scss" scoped>
.vehicle-search-bar {
    display: flex;
    align-items: center;
    min-width: 690px;
    border: 2px solid $color-neutral-lightest;
    border-radius: 18px;

    padding: 24px 18px 24px 5px;

    background-color: $color-white;

    @media (max-width: $screen-md) {
        min-width: auto;
        padding: 22px 18px 22px 5px;
        background-color: white;
    }
}

.search-icon {
    position: absolute;
    margin-left: 4px;
    opacity: 0.6;
    transform: scale(1.06);
    color: $color-neutral-dark;

    @media (max-width: $screen-md) {
        opacity: 1;
        transform: scale(1.2);;
    }
}

button {
    height: 18px;
    position: relative;
    bottom: 6px;
}
.close-icon {
    height: 32px;
}

input {
    width: 100%;
    margin-left: 40px;
    border: none;

    font-size: 16px;
    font-weight: 500;
    color: $color-neutral-deep;

    &::placeholder {
        color: $color-neutral-dark;
    }
}

input:focus {
    outline: none;
}

input:focus-visible {
    outline: none;
}
</style>
