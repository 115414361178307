<template>
    <div class="value-highlights-container">
        <div class="inner-container">
            <SkeletonLoader v-if="!content?.items?.length" :darkMode="true" />
            <template v-else>
                <h2 v-html="content.title"></h2>
                <div v-if="!isMobile" class="items-wrapper">
                    <div v-for="item in content.items" class="item">
                        <h3 class="text-gradient">
                            {{ item.title }}
                        </h3>
                        <p>{{ item.text }}</p>
                    </div>
                </div>
                <FaqCompact
                    v-else
                    :items="faqData"
                    :isDarkModeEnabled="true"
                    class="accordion-wrapper"
                />
            </template>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import SkeletonLoader from './UI-elements/SkeletonLoader.vue';
import FaqCompact from './FaqCompact.vue';

const props = defineProps({
    content: Object,
});

const store = useStore();
const isMobile = computed(() => store.state.device.isMobile);

const faqData = computed(() => {
    return props.content?.items?.map((item) => {
        return {
            question: item.title,
            answer: item.text,
        };
    });
});
</script>

<style lang="scss" scoped>
.value-highlights-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 150px 128px 150px;
    background: linear-gradient(
            256.68deg,
            #20273f 23.32%,
            rgba(44, 44, 51, 0.164) 53%,
            rgba(44, 44, 51, 0.164) 53%
        ),
        #1b1d21;

    @media (max-width: $screen-md) {
        padding: 96px 24px;
        font-size: 44px;
    }

    .inner-container {
        max-width: $container-max-width;
    }
}

h2 {
    color: $color-white;
}

.items-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    justify-items: left;
    justify-content: center;
    row-gap: 72px;
    column-gap: 62px;

    margin-top: 78px;

    @media (min-width: $screen-xxl) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.item {
    width: max(450px, 70%);

    h3 {
        margin-bottom: 24px;
        width: fit-content;
    }

    p {
        color: $color-neutral-pale;
        line-height: 27px;
    }

    @media (max-width: $screen-xl) {
        // width: 500px;
    }
}

.accordion-wrapper {
    margin-top: 78px;
}
</style>
